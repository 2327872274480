import { NgModule } from '@angular/core';
import { IconsModule, RemarketingUiComponentsLibModule, TableModule } from 'remarketing-ui-components-lib';
import { TransportationFeeModalComponent } from './transportation-fee-modal/transportation-fee-modal.component';
import { ErrorModalComponent } from './error-modal/error-modal.component';
import { CommonModule } from '@angular/common';
import { TranslocoRootModule } from 'smartauction-frontend-micro-library';
import { ServiceFeeScheduleModalComponent } from './service-fee-schedule-modal/service-fee-schedule-modal.component';
import {MultiVinErrorModalComponent} from './multi-vin-error-modal/multi-vin-error-modal.component';

@NgModule({
  declarations: [ErrorModalComponent, TransportationFeeModalComponent, ServiceFeeScheduleModalComponent, MultiVinErrorModalComponent],
  exports: [ErrorModalComponent, TransportationFeeModalComponent, ServiceFeeScheduleModalComponent, MultiVinErrorModalComponent],
  imports: [RemarketingUiComponentsLibModule, IconsModule, TableModule, CommonModule,TranslocoRootModule]
})
export class ModalModule {}

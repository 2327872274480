import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { get, pick } from 'lodash';

// TODO: move this into ngxs state
@Injectable({
  providedIn: 'root'
})
export class RegistrationStateService {

  private _configuration: string;

  constructor(private store: Store) {
    const configuration = this.store.select(state => state.configuration);
    configuration.subscribe(config => this._configuration = config);
  }

  get tenantUuid(): string {
    return get(this._configuration, 'configuration.tenantUuid');
  }

  get portalIdentifier(): string {
    return get(this._configuration, 'configuration.tenantName', null)?.toUpperCase();
  }

  // step 1
  dealerId: number;
  isAmbassador: boolean;

  // step 2
  firstName: string;
  lastName: string;
  userEmail: string;
  mobilePhone: string;
  auctionAccess: string;

  toJSON(): any {
    return pick(
      this,
      'portalIdentifier',
      'tenantUuid',
      'isAmbassador',
      'dealerId',
      'firstName',
      'lastName',
      'userEmail',
      'mobilePhone',
      'auctionAccess'
    );
  }

  reset(): void {
    this.isAmbassador = null;
    this.dealerId = null;
    this.firstName = null;
    this.lastName = null;
    this.userEmail = null;
    this.mobilePhone = null;
    this.auctionAccess = null;
  }
}

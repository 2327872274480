import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserSuggestionComponent } from './components/browser-suggestion/browser-suggestion.component';
import { PipeModule } from 'smartauction-frontend-micro-library';

@NgModule({
  declarations: [BrowserSuggestionComponent],
  imports: [CommonModule, PipeModule]
})
export class BrowserSuggestionModule {}

import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';

@Component({
  selector: 'app-browser-suggestion',
  templateUrl: './browser-suggestion.component.html',
  styleUrls: ['./browser-suggestion.component.scss']
})
export class BrowserSuggestionComponent implements OnInit {
  phoneNumber: string;

  constructor(private store: Store) {}

  ngOnInit() {
    this.phoneNumber = this.store.selectSnapshot(
      _state => _state.configuration.configuration.header.contactInfo.phoneNumber
    );
  }
}

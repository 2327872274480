import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService, LaunchDarklyService } from 'remarketing-angular-library';

@Injectable({
  providedIn: 'root'
})
export class DefaultGuard implements CanActivate {
  get authService(): AuthService {
    return AuthService.getInstance();
  }

  constructor(
    private readonly router: Router,
    private readonly _launchDarklyService: LaunchDarklyService
  ) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.authService.isAuthenticated()) {
      const userRoles = this.authService.getRoles();
      const hasBuyerRole = userRoles.includes('Dealer_Buyer');
      const hasSellerRole = userRoles.includes('Dealer_Seller');
      const hasAmbassadorRole = userRoles.includes('Ambassador');
      const dashboardEnabled = this._launchDarklyService.getFeatureFlag('dashboardEnabled', false);
      let targetRoute = '/search';

      if (hasAmbassadorRole) {
        targetRoute = '/ambassador-home';
      } else if (dashboardEnabled && hasBuyerRole || dashboardEnabled && hasSellerRole) {
        targetRoute = '/dashboard';
      }
      this.router.navigate([targetRoute]);
      return true;
    }
    else {
      this.router.navigate(['/login']);
      return false;
    }
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CollapseCardComponent } from './components/collapse-card/collapse-card.component';
import { ImagesCountComponent } from './components/images-count/images-count.component';
import { PlaceholderComponent } from './components/images-count/placeholder/placeholder.component';
import { BannerComponent } from './components/banner/banner.component';
import { TranslocoRootModule } from 'smartauction-frontend-micro-library';

@NgModule({
  declarations: [
    CollapseCardComponent,
    ImagesCountComponent,
    PlaceholderComponent,
    BannerComponent
  ],
  exports: [CollapseCardComponent, ImagesCountComponent, BannerComponent],
  imports: [CommonModule, TranslocoRootModule]
})
export class SellSharedModule {}

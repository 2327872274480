// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --vehicles` replaces `routes.ts` with `modules/routes.vehicles.ts`.
// The list of file replacements can be found in `angular.json`.
import { Routes } from '@angular/router';

import { AppRoute } from '../app/app.route';

export const DEFAULT_ROUTES: Routes = [
  AppRoute.PROFILE_MANAGEMENT,
  AppRoute.REGISTRATION,
  AppRoute.SEARCH,
  AppRoute.SUPPORT_PAGE,
  AppRoute.SELLING_DISCLOSURES,
  AppRoute.SUMMARY_DISCLOSURES,
  AppRoute.VEHICLES,
  AppRoute.TRANSPORTATION_HUB,
  AppRoute.SELL,
  AppRoute.AUCTION_SUMMARY,
  AppRoute.OFFER_LIST,
  AppRoute.TERMS_CONDITIONS,
  AppRoute.AMBASSADOR,
  AppRoute.AMBASSADOR_AUTHORIZATION,
  AppRoute.MESSAGES,
  AppRoute.DASHBOARD,
  AppRoute.UNSUBSCRIBE,
  AppRoute.DEALER_ONBOARDING_REGISTRATION
];

import { Component, ViewChild, Input, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, AbstractControl, UntypedFormControl, NgForm } from '@angular/forms';
import { get, filter, template, chain, isEmpty } from 'lodash';
import { MatchValuesValidator } from 'src/app/validators/match-values.validator';
import {LaunchDarklyService, UserService} from 'remarketing-angular-library';
import { AnalyticsService } from 'smartauction-frontend-micro-library';
import { Observable, Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { RegistrationStateService } from '../../services/registration-state/registration-state.service';
import { RegistrationWizardStep } from '../../interfaces/regisration-wizard-step.interface';
import { SmsModalComponent } from 'src/shared/components/sms-modal/sms-modal.component';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-registration-step3',
  templateUrl: './registration-step3.component.html',
  styleUrls: ['./registration-step3.component.scss']
})
export class RegistrationStep3Component implements OnDestroy, RegistrationWizardStep {
  private readonly destroyed$ = new Subject();
  readonly CONSTANTS: any = {
    title: '',
    username: '',
    password: '',
    reenterPassword: '',
    submit: '',
    cancel: '',

    reviewErrors: '',
    formInvalidSingular: '',
    formInvalidPlural: '',

    usernameError: '',
    usernameGenericError: '',
    usernameNotUnique: '',
    usernameHasSpecialCharacters: '',

    passwordEmptyError: '',
    passwordLowercaseError: '',
    passwordNumberError: '',
    passwordLengthError: '',
    passwordUppercaseError: '',
    passwordGreaterThanMax: '',
    passwordContainsUsername: '',

    reenterPasswordError: ''
  };

  rulesUsername: any;

  rulesPassword: any;

  private readonly patternUsername: string = '^[a-zA-Z0-9]*$';

  @ViewChild('ngForm', {static: true}) ngForm: NgForm;
  @ViewChild(SmsModalComponent) smsModal: SmsModalComponent;

  credentialsForm: UntypedFormGroup;
  formSubmitAttempted: boolean;
  formSubmitSuccessful: boolean;
  showDuplicateLoginError: boolean;
  unhandledError: string;

  @Input() current: boolean;

  private readonly usernamePattern = '^[a-zA-Z0-9]*$';

  constructor(
    private formBuilder: UntypedFormBuilder,
    private userService: UserService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private registrationState: RegistrationStateService,
    private readonly _analyticsService: AnalyticsService,
    private readonly _launchDarklyService: LaunchDarklyService,
    private readonly translocoService: TranslocoService
  ) {
    this.formSubmitAttempted = false;
    this.formSubmitSuccessful = false;
    this.resetErrors();
    this.createForm();
    this.getTranslations();
  }

  get username(): AbstractControl {
    return this.credentialsForm.controls.username;
  }

  get password(): AbstractControl {
    return this.credentialsForm.controls.password;
  }

  get showUsernameErrors(): boolean {
    return this.isUsernameEmpty || this.isUsernameInvalid;
  }

  get isUsernameInvalid(): boolean {
    return this.formSubmitAttempted && this.username.value.length > 0 && this.username.invalid;
  }

  get isUsernameEmpty(): boolean {
    return this.formSubmitAttempted && this.username.value.length === 0;
  }

  get usernameAlphanumeric(): boolean {
    const regex = /[a-zA-Z0-9]/;
    return regex.test(this.username.value);
  }

  get isPasswordInvalid(): boolean {
    return this.formSubmitAttempted && this.password.invalid;
  }

  get isPasswordEmpty(): boolean {
    return this.formSubmitAttempted && this.password.value.length === 0;
  }
  get passwordValidLength(): boolean {
    return this.formSubmitAttempted && this.password.value.length < 8;
  }

  get isPasswordGreaterThanMax(): boolean {
    return this.formSubmitAttempted && this.password.value.length > 20;
  }

  get passwordContainsUsername(): boolean {
    return !!get(this.credentialsForm, 'controls.password.errors.passwordContainsUsername');
  }

  get passwordHasLowercase(): boolean {
    const regex = /(?=.*[a-z])/;
    return this.passwordRegexCheck(regex);
  }

  get passwordHasNumber(): boolean {
    const regex = /(?=.*\d)/;
    return this.passwordRegexCheck(regex);
  }

  get tenantUuid(): string {
    return this.registrationState.tenantUuid;
  }

  get mobilePhone(): string {
    return this.registrationState.mobilePhone;
  }

  get passwordHasUppercase(): boolean {
    const regex = /(?=.*[A-Z])/;
    return this.passwordRegexCheck(regex);
  }

  get isPasswordConfirmationValid(): boolean {
    return this.formSubmitAttempted && this.credentialsForm.controls.passwordconfirmation.invalid;
  }

  get totalNumberErrors(): number {
    const controls = this.credentialsForm.controls;
    return filter(controls, control => control.invalid).length;
  }

  // @TODO: Watch for any exceptions similar to this
  // Error: ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked.
  // Previous value: 'undefined'. Current value: 'false'.
  // to fix these error added default value as false
  get isFormInvalid(): boolean {
    return (!!this.unhandledError || this.showDuplicateLoginError || (this.ngForm?.submitted && this.totalNumberErrors > 0)) || false;
  }

  get displayFormErrorsMessage(): string {
    const isPlural = this.totalNumberErrors > 1;
    const tmplName = isPlural ? 'formInvalidPlural' : 'formInvalidSingular';
    return template(this.CONSTANTS[tmplName])({ n: this.totalNumberErrors });
  }

  get isPristine(): boolean {
    return chain(this)
      .pick('username', 'password')
      .map(control => isEmpty(control.value))
      .reduce((isPristine, state) => isPristine && state)
      .value();
  }

  usernameChanged() {
    this.resetErrors();
  }

  restrictPwd(event: any): void {
    const noSpecialPattern = /[<>&%=?]/;
    const inputChar = String.fromCharCode(event.charCode);

    if (noSpecialPattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  onSubmit(): void {
    this.formSubmitAttempted = true;
    this.resetErrors();

    if (this.credentialsForm.valid) {
      this.spinner.show();
      this.createUserRequest()
        .pipe(
          takeUntil(this.destroyed$),
          finalize(() => this.spinner.hide()))
        .subscribe(() => this.createUserResponseSuccess(), err => this.createUserResponseFailure(err));
    } else {
      this.focusElement('reviewErrors_step3');
    }

    const errors = [];
    const passwordErrors = [];
    if (this.isUsernameEmpty) {
      errors.push(this.CONSTANTS.usernameError);
    }
    if (this.isUsernameInvalid) {
      errors.push(this.CONSTANTS.usernameGenericError);
    }

    if (this.isPasswordEmpty) {
      passwordErrors.push(this.CONSTANTS.passwordEmptyError);
    } else {
      if (this.passwordHasLowercase) {
        passwordErrors.push(this.CONSTANTS.passwordLowercaseError);
      }
      if (this.passwordHasNumber) {
        passwordErrors.push(this.CONSTANTS.passwordNumberError);
      }
      if (this.passwordValidLength) {
        passwordErrors.push(this.CONSTANTS.passwordLengthError);
      }
      if (this.passwordHasUppercase) {
        passwordErrors.push(this.CONSTANTS.passwordUppercaseError);
      }
      if (this.isPasswordGreaterThanMax) {
        passwordErrors.push(this.CONSTANTS.passwordGreaterThanMax);
      }
      if (this.passwordContainsUsername) {
        passwordErrors.push(this.CONSTANTS.passwordContainsUsername);
      }
    }

    if (this.isPasswordConfirmationValid) {
      errors.push(this.CONSTANTS.reenterPasswordError);
    }

    this._analyticsService.trackEndpoint({
      formValues: {
        name: 'Registration Whitelabel Create Credentials',
        status: this.credentialsForm.invalid ? 'error' : 'success',
        errors: errors.concat(passwordErrors),
        type: '',
        step: '3',
        stepname: '',
        items: [
          {
            allow: 'no',
            error: `${this.isUsernameEmpty ? this.CONSTANTS.usernameError : ''}${
              this.isUsernameInvalid ? ' ' + this.CONSTANTS.usernameGenericError : ''
            }`,
            name: 'User Name',
            track: 'yes',
            value: ''
          },
          {
            allow: 'no',
            error: passwordErrors.join(' '),
            name: 'Password',
            track: 'yes',
            value: ''
          },
          {
            allow: 'no',
            error: this.isPasswordConfirmationValid ? this.CONSTANTS.reenterPasswordError : '',
            name: 'Confirm Password',
            track: 'yes',
            value: ''
          }
        ]
      }
    });
  }

  canCompleteStep(): boolean {
    return true;
  }

  private createForm(): void {
    this.credentialsForm = this.formBuilder.group({
      username: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(8),
          Validators.pattern(this.usernamePattern)
        ])
      ],
      password: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(20),
          this.validatePasswordComplexity,
          this.validatePasswordNotMatchUsername
        ])
      ],
      passwordconfirmation: ['', [Validators.required, MatchValuesValidator('password')]]
    });
  }

  private createUserRequest(): Observable<any> {
    return this.userService.createUserUsingPublicApi({
      ...this.registrationState.toJSON(),
      userId: this.username.value,
      password: this.password.value
    });
  }

  private createUserResponseSuccess(): void {
    if (this.registrationState.mobilePhone && this.isSmsOtpEnabled()) {
      this.smsModal.show();
    } else {
      this.advanceWizard();
    }
  }

  isSmsOtpEnabled(): boolean {
    return this._launchDarklyService.getFeatureFlag('smsOtpEnabled', false);
  }

  private createUserResponseFailure(err: any): void {
    this.formSubmitSuccessful = false;
    const isDuplicateUser = err.error.errors.filter((e: any) => e.detail.match('DUPLICATE_USER_ID_ERROR'));
    this.showDuplicateLoginError = isDuplicateUser && isDuplicateUser.length > 0;
    if (!this.showDuplicateLoginError && err.error.errors.length > 0) {
      this.unhandledError = `${err.error.errors[0].code} ${err.error.errors[0].detail}`;
    }
    this.focusElement('reviewErrors_step3');
  }

  private passwordRegexCheck(regex: RegExp): boolean {
    const password = this.password.value;
    return this.formSubmitAttempted && !regex.test(password);
  }

  private validatePasswordComplexity(c: UntypedFormControl): {} {
    const PASSWORD_COMPLEXITY = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/;

    return PASSWORD_COMPLEXITY.test(c.value)
      ? null
      : {
        validatePassword: {
          valid: false
        }
      };
  }

  private validatePasswordNotMatchUsername(control: UntypedFormControl): {} {
    const password = control.value;
    const usernameObject = control.root.get('username');
    const username = usernameObject ? usernameObject.value : '';

    return !(password.toLowerCase().includes(username.toLowerCase())) ? null : { passwordContainsUsername: true };
  }

  focusElement(id) {
    setTimeout(() => {
      const message = document.getElementById(id) as HTMLElement;
      if (message) {
        message.focus();
      }
    }, 100);
  }

  advanceAfterSmsModal(isSuccessfullyEnrolled: boolean) {
    this.advanceWizard();
  }

  private advanceWizard() {
    this.formSubmitSuccessful = true;
    this.router.navigate(['/register/confirmation']);
  }

  getTranslations(){
    this.translocoService.selectTranslateObject('registration.registrationStep3').pipe(takeUntil(this.destroyed$)).subscribe(translate => {
      this.CONSTANTS.title = translate.title;
      this.CONSTANTS.username = translate.username;
      this.CONSTANTS.password = translate.password;
      this.CONSTANTS.reenterPassword = translate.reenterPassword;
      this.CONSTANTS.formInvalidSingular = translate.formInvalidSingular;
      this.CONSTANTS.formInvalidPlural = translate.formInvalidPlural;
      this.CONSTANTS.usernameError = translate.usernameError;
      this.CONSTANTS.usernameGenericError = translate.usernameGenericError;
      this.CONSTANTS.usernameNotUnique = translate.usernameNotUnique;
      this.CONSTANTS.usernameHasSpecialCharacters = translate.usernameHasSpecialCharacters;
      this.CONSTANTS.reviewErrors = translate.reviewErrors;
      this.CONSTANTS.passwordEmptyError = translate.passwordEmptyError;
      this.CONSTANTS.passwordLowercaseError = translate.passwordLowercaseError;
      this.CONSTANTS.passwordNumberError = translate.passwordNumberError;
      this.CONSTANTS.passwordLengthError = translate.passwordLengthError;
      this.CONSTANTS.passwordUppercaseError = translate.passwordUppercaseError;
      this.CONSTANTS.passwordGreaterThanMax = translate.passwordGreaterThanMax;
      this.CONSTANTS.passwordContainsUsername = translate.passwordContainsUsername;
      this.CONSTANTS.reenterPasswordError = translate.reenterPasswordError;

      this.rulesUsername = [
        {
          label: translate.ruleUserName,
          isValid(value: string): boolean {
            return value.length == 8 && /^[a-zA-Z0-9]+$/.test(value);
          }
        }
      ];

      this.rulesPassword = [
        {
          label: translate.ruleCharacter,
          isValid(credentialsForm: UntypedFormGroup): boolean {
            return credentialsForm.controls.password.value.length >= 8 && credentialsForm.controls.password.value.length <= 20;
          }
        },
        {
          label: translate.ruleUppercaseLetter,
          isValid(credentialsForm: UntypedFormGroup): boolean {
            return /(?=.*[A-Z])/.test(credentialsForm.controls.password.value);
          }
        },
        {
          label: translate.ruleLowercaseLetter,
          isValid(credentialsForm: UntypedFormGroup): boolean {
            return /(?=.*[a-z])/.test(credentialsForm.controls.password.value);
          }
        },
        {
          label: translate.ruleNumber,
          isValid(credentialsForm: UntypedFormGroup): boolean {
            return /(?=.*\d)/.test(credentialsForm.controls.password.value);
          }
        },
        {
          label: translate.ruleCantUserName,
          isValid(credentialsForm: UntypedFormGroup): boolean {
            return credentialsForm.controls.password.value &&
            !(credentialsForm.controls.password.value.toLowerCase().includes(credentialsForm.controls.username.value.toLowerCase()));
          }
        }
      ];
    });

    this.translocoService.selectTranslateObject('common').pipe(takeUntil(this.destroyed$)).subscribe(translate => {
      this.CONSTANTS.submit = translate.labelSubmit;
      this.CONSTANTS.cancel = translate.btnCancel;
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  resetErrors(): void {
    this.showDuplicateLoginError = false;
    this.unhandledError = '';
  }
}

<ng-container *transloco="let convert;">
  <nav class="wizard-steps" role="navigation" [attr.aria-label]="convert('registration.registrationNevigation.ariaLabelRegistrationSteps')">
    <p [innerHTML]="convert('registration.registrationNevigation.textStep', {currentStep : currentStep, totalSteps : totalSteps})"></p>

    <ol>
      <li
        *ngFor="let step of steps; let i = index"
        [class.is-selected]="step.selected"
        [class.is-complete]="step.completed"
        [class.is-upcoming]="!step.selected && !step.completed"
      >
        <a data-track-elem="button" [attr.data-track-name]="step.stepTitle" hfref="#" [awGoToStep]="step"
          tabIndex="0"
          [attr.aria-label]="stepAriaLabel(i, step.stepTitle, step)" >
          <span class="step-index"></span> {{ step.stepTitle }}
        </a>
      </li>
    </ol>
  </nav>
</ng-container>
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { get } from 'lodash';

type EnvironmentName = string;
type FeatureName = string;

interface FeatureEnvironmentMap {
    [featureName: string]: EnvironmentName[];
}

@Injectable({
  providedIn: 'root'
})
export class FeatureService {

  private get environmentName(): string {
    return environment.name;
  }

  private featureEnvironmentMap: FeatureEnvironmentMap = {
    sell: ['local', 'dev', 'qa', 'uat', 'cap', 'prod', 'production']
  };

  public enabled(name: FeatureName): boolean {
    return get(this.featureEnvironmentMap, name, []).some(e => e === this.environmentName);
  }
}

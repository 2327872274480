<app-offer-modals-header heading="" [tenantName]="tenantName"></app-offer-modals-header>
<div class="action action-errored text-centered">
  <div class="action-errored__heading" tabindex="-1" #heading aria-live="polite">
    <em class="allycon-error-fill indicator-error me-1"></em>
    <span class="action-errored__tagline" id="action_error_text">{{ errorText }}</span>
  </div>

  <div class="action-errored__actions modal__actions">
    <button
      class="btn btn-primary mb-2"
      type="button"
      (click)="closeModal()"
      data-track-elem="button"
      data-track-name="Close"
      data-track-trigger="close"
      id="action_confirmation_close_button"
    >
      Close
    </button>
  </div>
</div>
<app-modal-close (close)="closeModal()" [identifier]="'action_confirmation_close_x_button'"></app-modal-close>

<ng-container *transloco="let convert;">
<header>
  <h1 tabindex="-1" class="step3Header" id="{{ current ? 'main-content-focus' : '' }}">{{ CONSTANTS.title }}</h1>
  <p>
    {{convert('registration.registrationStep3.textUserName')}}
  </p>
</header>
</ng-container>

<section class="step-content">
  <form [formGroup]="credentialsForm" #ngForm="ngForm" (submit)="onSubmit()">
    <p
      id="reviewErrors_step3"
      *ngIf="isFormInvalid"
      [class.is-invalid]="isFormInvalid"
      aria-live="polite"
      tabindex="-1"
    >
      <i class="allycon-error-fill"></i>
      {{ displayFormErrorsMessage }} {{ CONSTANTS.reviewErrors }}
  </p>

    <div class="form-group">
      <label for="username" id="username-label">{{ CONSTANTS.username }}</label>
      <div class="d-md-flex">
        <div class="control-wrapper col-md-6 col-lg-6 col-xl-4 px-0 me-md-4">
          <input
            type="text"
            class="form-control col-sm-12"
            id="username"
            (keyup)="usernameChanged()"
            formControlName="username"
            [class.is-invalid]="showUsernameErrors || showDuplicateLoginError || unhandledError"
            aria-labelledby="username-label username-error_username username-error_generic username-error_unique username-error_characters"
            aria-required="true"
          />

          <span *ngIf="isUsernameEmpty" class="invalid-feedback" id="username-error_username" aria-live="polite">{{
            CONSTANTS.usernameError
          }}</span>
          <span *ngIf="isUsernameInvalid" class="invalid-feedback" id="username-error_generic" aria-live="polite">{{
            CONSTANTS.usernameGenericError
          }}</span>
          <span
            *ngIf="formSubmitAttempted && showDuplicateLoginError"
            class="invalid-feedback"
            id="username-error_unique"
            aria-live="polite"
            >{{ CONSTANTS.usernameNotUnique }}</span
          >
          <span
            *ngIf="unhandledError"
            class="invalid-feedback"
            id="username-error_unique"
            aria-live="polite"
            >{{ unhandledError }}</span
          >
          <span
            *ngIf="credentialsForm.controls.username.valid && formSubmitAttempted && !usernameAlphanumeric"
            class="invalid-feedback"
            id="username-error_characters"
            aria-live="polite"
            >{{ CONSTANTS.usernameHasSpecialCharacters }}</span
          >
        </div>

        <div class="indicator-wrapper mt-3 mt-md-0">
          <app-complexity-indicator
            [value]="credentialsForm.controls.username.value"
            [rules]="rulesUsername"
            id="username-desc"
          ></app-complexity-indicator>
        </div>
      </div>
    </div>

    <div class="form-group">
      <label for="password" id="password-label">{{ CONSTANTS.password }}</label>
      <div class="d-md-flex">
        <div class="control-wrapper col-md-6 col-lg-6 col-xl-4 px-0 me-md-4">
          <input
            type="password"
            (keypress)="restrictPwd($event)"
            class="form-control col-sm-12"
            id="password"
            formControlName="password"
            [class.is-invalid]="isPasswordInvalid"
            aria-labelledby="password-label password-desc password-error_empty password-error_lowercase password-error_number password-error_length password-error_uppercase password-error_max password-error_match"
            aria-required="true"
          />

          <span *ngIf="isPasswordEmpty" class="invalid-feedback" id="password-error_empty" aria-live="polite">{{
            CONSTANTS.passwordEmptyError
          }}</span>
          <span
            *ngIf="!isPasswordEmpty && passwordHasLowercase"
            class="invalid-feedback"
            id="password-error_lowercase"
            aria-live="polite"
            >{{ CONSTANTS.passwordLowercaseError }}</span
          >
          <span
            *ngIf="!isPasswordEmpty && passwordHasNumber"
            class="invalid-feedback"
            id="password-error_number"
            aria-live="polite"
            >{{ CONSTANTS.passwordNumberError }}</span
          >
          <span
            *ngIf="!isPasswordEmpty && passwordValidLength"
            class="invalid-feedback"
            id="password-error_length"
            aria-live="polite"
            >{{ CONSTANTS.passwordLengthError }}</span
          >
          <span
            *ngIf="!isPasswordEmpty && passwordHasUppercase"
            class="invalid-feedback"
            id="password-error_uppercase"
            aria-live="polite"
            >{{ CONSTANTS.passwordUppercaseError }}</span
          >
          <span
            *ngIf="!isPasswordEmpty && isPasswordGreaterThanMax"
            class="invalid-feedback"
            id="password-error_max"
            aria-live="polite"
            >{{ CONSTANTS.passwordGreaterThanMax }}</span
          >
          <span
            *ngIf="
              !isPasswordEmpty &&
              formSubmitAttempted &&
              credentialsForm.controls.password.errors?.passwordContainsUsername
            "
            class="invalid-feedback"
            id="password-error_match"
            aria-live="polite"
            >{{ CONSTANTS.passwordContainsUsername }}</span
          >
        </div>

        <div class="indicator-wrapper indicator-large">
          <app-complexity-indicator
            [value]="credentialsForm"
            [rules]="rulesPassword"
            id="password-desc"
          ></app-complexity-indicator>
        </div>
      </div>
    </div>

    <div class="form-group">
      <label for="password-confirmation" id="password-confirmation-label">{{ CONSTANTS.reenterPassword }}</label>
      <div class="control-wrapper">
        <input
          type="password"
          class="form-control"
          (paste)="$event.preventDefault()"
          id="password-confirmation"
          formControlName="passwordconfirmation"
          [class.is-invalid]="isPasswordConfirmationValid"
          aria-labelledby="password-confirmation-label password-confirmation-error password-confirmation-desc"
          aria-required="true"
        />
        <span
          *ngIf="isPasswordConfirmationValid"
          class="invalid-feedback"
          id="password-confirmation-error"
          aria-live="polite"
          >{{ CONSTANTS.reenterPasswordError }}</span
        >
      </div>

      <div class="indicator-wrapper indicator-small mt-3">
        <app-complexity-indicator
          [value]="credentialsForm"
          [rules]="rulesPassword"
          id="password-confirmation-desc"
        ></app-complexity-indicator>
      </div>
    </div>

    <div class="mt-4 control-wrapper__actions">
      <button
        data-track-elem="button"
        [attr.data-track-name]="CONSTANTS.submit"
        type="submit"
        class="btn btn-primary"
        id="step3-next"
        [appTrackButton]="CONSTANTS.submit"
      >
        {{ CONSTANTS.submit }}
      </button>
      <button
        data-track-elem="button"
        [attr.data-track-name]="CONSTANTS.cancel"
        type="button"
        routerLink="/login"
        class="btn btn-link cancel-btn"
        id="step3-cancel"
        [appTrack]="{ name: CONSTANTS.cancel, trigger: 'interstital' }"
      >
        {{ CONSTANTS.cancel }}
      </button>
    </div>
  </form>
</section>

<app-sms-modal
  [tenantUuid]="tenantUuid"
  [userId]="username.value"
  [mobilePhone]="mobilePhone"
  (mobileVerified)="advanceAfterSmsModal($event)"
></app-sms-modal>

<section class="wrapper">
  <div class="container">
    <div class="layout mt-2">
      <h1 id="main-content-focus" class="text-brand mb-3" tabindex="-1">Improve Your SmartAuction Experience</h1>
      <p>
        The browser you're using may be missing key SmartAuction functionality. Please use one of the free browsers
        below for the best experience on SmartAuction.
      </p>
      <p>Please call us at {{ phoneNumber | formatPhoneNumber }} if you have any questions.</p>

      <div class="browser-suggestions">
        <div class="browser-suggestion">
          <a href="https://www.google.com/chrome/" target="_blank" rel="noopener nofollow" class="fw-bold">
            <img src="assets/img/google-chrome.png" alt="Get Google Chrome" />
            Chrome
          </a>
        </div>
        <div class="browser-suggestion">
          <a
            href="https://www.mozilla.org/en-US/firefox/new/"
            target="_blank"
            rel="noopener nofollow"
            class="fw-bold"
          >
            <img src="assets/img/Fx-Browser-icon-fullColor-512.png" alt="Get Mozilla Firefox" />
            Firefox
          </a>
        </div>
        <div class="browser-suggestion">
          <a
            href="https://www.microsoft.com/en-us/windows/microsoft-edge"
            target="_blank"
            rel="noopener nofollow"
            class="fw-bold"
          >
            <img src="assets/img/microsoft-edge.png" alt="Get Microsoft Edge" />
            Edge
          </a>
        </div>
      </div>

      <p>
        The icons and links in this notice will take you to third party sites not operated by Ally. We provide these
        links as a convenience, but we are not responsible for, nor do we endorse, the content of these sites. Please
        review their privacy and security policies and website terms.
      </p>
    </div>
  </div>
</section>

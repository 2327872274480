import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-registration-cancel-modal',
  templateUrl: './registration-cancel-modal.component.html',
  styleUrls: ['./registration-cancel-modal.component.scss']
})
export class RegistrationCancelModalComponent {

  didConfirm$: Subject<boolean> = new Subject();
  didDecline$: Subject<boolean> = new Subject();

  private modalRef: BsModalRef;

  handleConfirm() {
    this.didConfirm$.next(true);
    this.didConfirm$.complete();
  }

  handleDecline() {
    this.didDecline$.next(false);
    this.didDecline$.complete();
  }

}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from 'remarketing-angular-library';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {
  get hasAuth(): boolean {
    return this.authService.isAuthenticated();
  }

  get authService(): AuthService {
    return AuthService.getInstance();
  }

  constructor(private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.hasAuth) {
      this.router.navigate(['/']);
      return false;
    }
    return true;
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { A11yNumberLabelDirective } from './directives/a11y-number-label.directive';
import { A11yAddressLineComponent } from './components/a11y-address-line/a11y-address-line.component';

@NgModule({
  declarations: [A11yNumberLabelDirective, A11yAddressLineComponent],
  imports: [
    CommonModule
  ],
  exports: [A11yNumberLabelDirective, A11yAddressLineComponent]
})
export class A11yModule { }

<header>
  <h1 tabindex="-1" class="step2Header" id="{{current ? 'main-content-focus': ''}}">{{ CONSTANTS.title }}</h1>
</header>

<form [formGroup]="userForm" #ngForm="ngForm" class="step-content" (ngSubmit)="handleContinue()">
  <p id="reviewErrors_step2" *ngIf="isFormInvalid" [class.is-invalid]="isFormInvalid" aria-live="polite" tabindex="-1">
    <i class="allycon-error-fill"></i>
    {{ displayFormErrorsMessage }} {{ CONSTANTS.reviewErrors }}
  </p>

  <div class="form-group">
    <label for="firstName" id="firstName-label">{{ CONSTANTS.firstName }}</label>
    <div class="control-wrapper">
      <input formControlName="firstName" id="firstName" class="form-control" [class.is-invalid]="isFirstNameInvalid" aria-required=“true” aria-labelledby="firstName-label firstName-error">
      <span *ngIf="isFirstNameInvalid" class="invalid-feedback" id="firstName-error">{{ CONSTANTS.errorMessages.firstName }}</span>
    </div>
  </div>

  <div class="form-group">
    <label for="lastName" id="lastName-label">{{ CONSTANTS.lastName }}</label>
    <div class="control-wrapper">
      <input formControlName="lastName" id="lastName" class="form-control" [class.is-invalid]="isLastNameInvalid" aria-required=“true” aria-labelledby="lastName-label lastName-error">
      <span *ngIf="isLastNameInvalid" class="invalid-feedback" id="lastName-error">{{ CONSTANTS.errorMessages.lastName }}</span>
    </div>
  </div>

  <div class="form-group">
    <label for="email" id="email-label">{{ CONSTANTS.email }}</label>
    <div class="control-wrapper">
      <input formControlName="userEmail" id="email" class="form-control" [class.is-invalid]="isEmailInvalid" aria-required=“true” aria-labelledby="email-label email-error">
      <span *ngIf="invalidFormatEmail" class="invalid-feedback" id="email-error-format">{{ CONSTANTS.errorMessages.invalidFormatEmail }}</span>
      <span *ngIf="emailContainsSpecialChars" class="invalid-feedback" id="email-error">{{ CONSTANTS.errorMessages.emailContainsSpecialChars }}</span>
    </div>
  </div>

  <div class="form-group">
    <label for="mobilePhone" id="mobilePhone-label">{{ CONSTANTS.mobilePhone }} <span class="text-lower">({{ CONSTANTS.optional }})</span></label>
    <div class="control-wrapper">
      <input formControlName="mobilePhone" id="mobilePhone" class="form-control" [class.is-invalid]="isMobilePhoneInvalid" aria-labelledby="mobilePhone-label mobilePhone-error">
      <span *ngIf="isMobilePhoneInvalid" class="invalid-feedback" id="mobilePhone-error">{{ CONSTANTS.errorMessages.mobilePhone }}</span>
    </div>
  </div>

  <div *ngIf="featureMobileNotif" class="form-group">
    <p class="mobilePhoneClause">{{ CONSTANTS.mobilePhoneClause }}</p>
  </div>

  <div *ngIf="showAuctionAccessId" class="form-group">
    <label for="auctionAccess" id="auctionAccess-label">{{ CONSTANTS.auctionAccess }} <span *ng-if="isAuctionAccessIdOptional" class="text-lower">({{ CONSTANTS.optional }})</span></label>
    <div class="control-wrapper">
      <input formControlName="auctionAccess" id="auctionAccess" class="form-control" [class.is-invalid]="isAuctionAccessInvalid" placeholder="{{ CONSTANTS.auctionAccessIdPlaceHolder }}" aria-labelledby="auctionAccess-label auctionAccess-error">
      <span *ngIf="isAuctionAccessInvalid" class="invalid-feedback" id="auctionAccess-error">{{ CONSTANTS.errorMessages.auctionAccess }}</span>
    </div>
  </div>

  <div class="mt-4 control-wrapper__actions">
    <button data-track-elem="button" [attr.data-track-name]="CONSTANTS.button.continue" type="submit" class="btn btn-primary" [appTrackButton]="CONSTANTS.button.continue">{{ CONSTANTS.button.continue }}</button>
    <button data-track-elem="button" [attr.data-track-name]="CONSTANTS.button.cancel" type="button" routerLink="/login" class="btn btn-link cancel-btn"  [appTrack]="{name: CONSTANTS.button.cancel, trigger: 'interstital'}">{{ CONSTANTS.button.cancel }}</button>
  </div>
</form>

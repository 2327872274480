import { Injectable } from '@angular/core';
import { noop } from 'lodash';
declare let browser: any;

@Injectable({ providedIn: 'root' })
export class UtilityService {
  constructor() {}

  injectStyleSheet(tenantName: string, onload: () => void = noop) {
    const head = document.getElementsByTagName('head')[0];
    const linkTag = document.createElement('link');
    linkTag.rel = 'stylesheet';
    linkTag.href = `/ui/${tenantName}.css`;
    linkTag.onload = onload;
    head.appendChild(linkTag);
  }
}

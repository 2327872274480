import { Component, OnInit } from '@angular/core';
import { RegistrationStateService } from '../../services/registration-state/registration-state.service';
import { template } from 'lodash';
import { TranslocoService } from '@ngneat/transloco';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-registration-confirmation',
  templateUrl: './registration-confirmation.component.html',
  styleUrls: ['./registration-confirmation.component.scss']
})
export class RegistrationConfirmationComponent implements OnInit {

  private readonly destroyed$ = new Subject();
  emailAddress: string;

  readonly CONSTANTS = {
    title:   '',
    message: ''
  };

  get confirmationMessage(): string {
    return template(this.CONSTANTS.message)({ emailAddress: this.emailAddress });
  }

  constructor(
    private readonly registrationState: RegistrationStateService,
    private readonly translocoService: TranslocoService
  ) { }

  ngOnInit() {
    this.getTranslations();
    this.emailAddress = this.registrationState.userEmail;
    this.registrationState.reset();

    setTimeout(() => {
      const heading = document.getElementsByClassName('confirmationHeader') as HTMLCollectionOf<HTMLElement>;
      heading[0].focus();
    }, 200);
  }

  getTranslations(){
    this.translocoService.selectTranslate('registration.registrationConfirmation.textTitle').pipe(takeUntil(this.destroyed$)).subscribe(translate => {
      this.CONSTANTS.title = translate;
    });

    this.translocoService.selectTranslate('registration.registrationConfirmation.textMessage', {emailAddress : this.emailAddress}).pipe(takeUntil(this.destroyed$)).subscribe(translate => {
      this.CONSTANTS.message = translate;
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}

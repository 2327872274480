import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { Modal, ModalComponent } from 'remarketing-ui-components-lib';

@Component({
  selector: 'lib-save-comment-modal',
  templateUrl: './save-comment-modal.component.html',
  styleUrls: ['./save-comment-modal.component.scss']
})
export class SaveCommentModalComponent implements OnDestroy, Modal {
  @Output() closed = new EventEmitter<void>();
  @ViewChild(ModalComponent) modal: ModalComponent;
  tenantName = '';
  state: 'loading' | 'success' | 'error' = 'loading';
  @Input() errorText: string;

  constructor(private readonly changeDetector: ChangeDetectorRef) { }

  close() {
    this.modal.close();
  }
  show() {
    this.changeDetector.markForCheck();
    this.modal.show();
  }

  ngOnDestroy(): void {
    if (this.modal) {
      this.modal.close();
    }
  }
}

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { OktaAuthConfig, logrocketInitialization, logrocketLogSession} from 'smartauction-frontend-micro-library';
import * as LogRocket from 'logrocket';
import { environment } from 'src/environments/environment';

const logRocketSanitizeObj = {
  launchDarklyKey: environment.launchDarklyKey
};

logrocketInitialization(logRocketSanitizeObj);
logrocketLogSession();

if (window.frameElement){
  console.log('site is framed, firing custom event');
  LogRocket.track('AuctionGenius');
}
else {
  console.log('Site is not framed, not firing custom event');
}

if (environment.production) {
  enableProdMode();
}

if(!sessionStorage.getItem('authConfig')) {
  fetch(`/api/platform-configuration/v1/tenant-configs/login/${location.host}`).then(async res => {
    const authConfig = new OktaAuthConfig(await res.json());
    sessionStorage.setItem('authConfig', JSON.stringify(authConfig));
    platformBrowserDynamic([{ provide: OktaAuthConfig, useValue: authConfig }])
      .bootstrapModule(AppModule)
      .catch(err => console.error(err));
  });
} else {
  platformBrowserDynamic([{ provide: OktaAuthConfig, useValue: JSON.parse(sessionStorage.getItem('authConfig')) }])
    .bootstrapModule(AppModule)
    .catch(err => console.error(err));
}

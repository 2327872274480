import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OffersShellComponent } from './components/offers-shell/offers-shell.component';
import { HistoryComponent } from './components/history/history.component';
import { DrawerComponent } from './components/drawer/drawer.component';
import { CounterComponent } from './components/counter/counter.component';
import { DeclineComponent } from './components/decline/decline.component';
import { ReviveComponent } from './components/revive/revive.component';
import { AcceptComponent } from './components/accept/accept.component';
import { RemarketingUiComponentsLibModule } from 'remarketing-ui-components-lib';
import { SellSharedModule } from '../sell-shared/sell-shared.module';
import { OfferComponent } from './components/offer/offer.component';
import { WithdrawComponent } from './components/withdraw/withdraw.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { PipeModule, TranslocoRootModule } from 'smartauction-frontend-micro-library';
import { SharedModule } from 'src/shared/components/shared.module';

@NgModule({
  declarations: [
    OffersShellComponent,
    HistoryComponent,
    DrawerComponent,
    CounterComponent,
    DeclineComponent,
    ReviveComponent,
    AcceptComponent,
    OfferComponent,
    WithdrawComponent
  ],
  imports: [
    CommonModule,
    RemarketingUiComponentsLibModule,
    SellSharedModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule.forChild(),
    TranslocoRootModule,
    SharedModule,
    PipeModule
  ]
})
export class OffersModule {}

<ng-container *transloco="let convert;">
<aw-wizard #wizard [navBarLocation]="''" class="wizard-ui">
  <app-registration-navigation></app-registration-navigation>

  <div class="wizard-content">
    <aw-wizard-step stepTitle="{{convert('registration.registrationWizard.titleSelectDealership')}}" [canExit]="canContinue" >
      <app-registration-step1                           
                            (goToNextStep)="willEnterStep()"
                            [current]="current === 'step1Header'"></app-registration-step1>
    </aw-wizard-step>
    <aw-wizard-step stepTitle="{{convert('registration.registrationWizard.titleUserInformation')}}" [canExit]="canContinue">
      <app-registration-step2                             
                            (goToNextStep)="willEnterStep()"
                            (stepEnter)="headerFocus('step2Header')"
                            [current]="current === 'step2Header'"
                            (stepExit)="headerFocus('step1Header')"></app-registration-step2>
    </aw-wizard-step>
    <aw-wizard-step stepTitle="{{convert('registration.registrationWizard.titleCreateCredentials')}}" [canExit]="canContinue">
      <app-registration-step3                            
                            (goToNextStep)="willEnterStep()"
                            (stepEnter)="headerFocus('step3Header')"
                            [current]="current === 'step3Header'"></app-registration-step3>
    </aw-wizard-step>
  </div>
</aw-wizard>
</ng-container>

import { NgModule } from '@angular/core';
import { Routes, RouterModule, ActivatedRoute, Router } from '@angular/router';
import { DEFAULT_ROUTES } from 'src/environments/routes';
import { LaunchDarklyTenantGuard, SetCpCode, LaunchDarklyService } from 'remarketing-angular-library';
import { LoginGuard } from './guards/login/login.guard';
import { BrowserSuggestionComponent } from './modules/browser-suggestion/components/browser-suggestion/browser-suggestion.component';
import { Store } from '@ngxs/store';
import { DefaultGuard } from './guards/default/default.guard';

import {
  ExternalUrlRedirectResolver,
  AuthCodeComponent,
  AdminErrorGuard,
  AnalyticsService,
  AdminErrorComponent,
  WhiteLabelCallbackGuard,
  AdminLoginGuard,
  ForgotUsernameComponent,
  ErrorComponent,
  LogoutComponent,
  LoginComponent,
  AdminTransportationGuard,
  LegacyCallbackGuard
} from 'smartauction-frontend-micro-library';import { skipWhile, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { DefaultComponent } from 'remarketing-ui-components-lib';

// Route definitions
export const envRoutes = DEFAULT_ROUTES;
export const routes: Routes = [
  { path: 'legacy/callback', children: [], canActivate: [LegacyCallbackGuard] },
  { path: 'auth-user/callback', component: AuthCodeComponent, canActivate: [WhiteLabelCallbackGuard] },
  {
    path: 'home',
    component: DefaultComponent,
    resolve: { url: 'externalUrlRedirectResolver' },
    canActivate: [LaunchDarklyTenantGuard],
    data: { flag: 'login-landingPageEnabled', externalUrl: `${window.location.protocol}//${location.host}/home` }
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [LoginGuard],
    data: { environment: environment}
  },
  { path: 'login/admin', children: [], canActivate: [AdminLoginGuard] },
  { path: 'login/admin/error', component: AdminErrorComponent, canActivate: [AdminErrorGuard] },
  { path: 'login/forgot-username', component: ForgotUsernameComponent, canActivate: [LoginGuard] },
  { path: 'logout', component: LogoutComponent },
  { path: 'browser', component: BrowserSuggestionComponent },
  { path: 'error', component: ErrorComponent },
  { path: '404', component: ErrorComponent, data: { type: '404' } },
  { path: 'visibility-error', component: ErrorComponent, data: { type: 'visibility' } },
  // Routing and LD flag guard handled in the fe-statements micro-ui
  {
    path: 'privacy',
    component: DefaultComponent,
    resolve: { url: 'externalUrlRedirectResolver' },
    data: { externalUrl: `${window.location.protocol}//${location.host}/tou/privacy` }
  },
  {
    path: 'privacy/FULL',
    component: DefaultComponent,
    resolve: { url: 'externalUrlRedirectResolver' },
    data: { externalUrl: `${window.location.protocol}//${location.host}/tou/privacy/FULL` }
  },
  {
    path: 'terms-of-use',
    component: DefaultComponent,
    resolve: { url: 'externalUrlRedirectResolver' },
    data: { externalUrl: `${window.location.protocol}//${location.host}/tou/terms-of-use` }
  },
  {
    path: 'terms-of-use/FULL',
    component: DefaultComponent,
    resolve: { url: 'externalUrlRedirectResolver' },
    data: { externalUrl: `${window.location.protocol}//${location.host}/tou/terms-of-use/FULL` },
  },
  {
    path: 'admin-transportation',
    component: DefaultComponent,
    resolve: { url: 'externalUrlRedirectResolver' },
    data: { externalUrl: `${window.location.protocol}//${location.host}/adm/admin-transportation` },
    canActivate: [AdminTransportationGuard]
  },
  {
    path: '',
    children: [
      ...envRoutes,
      {
        path: '',
        redirectTo: '',
        pathMatch: 'full',
        canActivate: [DefaultGuard]
      }
    ]
  },
  {
    path: '**',
    redirectTo: '/404',
    pathMatch: 'full'
  }
];

@NgModule({
  providers: [
  {
  provide: 'externalUrlRedirectResolver',
  useClass: ExternalUrlRedirectResolver
  }
  ],
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' , canceledNavigationResolution: 'computed'})],
  exports: [RouterModule]
  })
export class AppRoutingModule {
  constructor(
    private activatedRoute: ActivatedRoute,
    private analyticsService: AnalyticsService,
    private store: Store,
    private router: Router,
    private launchDarklyService: LaunchDarklyService
  ) {
    // this.handleDynamicRoutesByLaunchDarklyConfig();

    this.activatedRoute.queryParams.subscribe(params => {
      if (params.cp) {
        this.analyticsService.digitalData.user.cpCode = params.cp;
        this.store.dispatch(new SetCpCode(params.cp));
      }
    });
  }

  handleDynamicRoutesByLaunchDarklyConfig() {
    this.launchDarklyService
      .waitForLaunchDarkly()
      .pipe(
        skipWhile(ready => !ready),
        take(1)
      )
      .subscribe((ready: boolean) => {
        if (ready) {
          const landingPageMicroUiEnabled = this.launchDarklyService.getFeatureFlag('microui-route-homeEnabled', false);

          let routerConfig = this.router.config;
          if (landingPageMicroUiEnabled) {
            routerConfig = [
              {
                path: 'home',
                component: DefaultComponent,
                resolve: {
                  url: 'externalUrlRedirectResolver'
                },
                canActivate: [LaunchDarklyTenantGuard],
                data: {
                  flag: 'login-landingPageEnabled',
                  externalUrl: `${window.location.protocol}//${location.host}/home`
                }
              },
              ...routerConfig
            ];
          } else {
            routerConfig = [
              {
                path: 'home',
                component: DefaultComponent, //Temp value for removing landing page
                canActivate: [LaunchDarklyTenantGuard],
                data: { flag: 'login-landingPageEnabled' }
              },
              ...routerConfig
            ];
          }
          this.router.resetConfig(routerConfig);
        }
      });
  }
}

import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditCommentComponent } from './edit-comment/edit-comment.component';
import { SaveCommentModalComponent } from './save-comment-modal/save-comment-modal.component';
import { ModalPortalDirective } from './modal-portal/modal-portal.directive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { RemarketingUiComponentsLibModule } from 'remarketing-ui-components-lib';
import { ActionSubmittingComponent } from './modal-actions/action-submitting/action-submitting.component';
import { ActionSubmittedComponent } from './modal-actions/action-submitted/action-submitted.component';
import { ModalCloseComponent } from './modal-actions/modal-close/modal-close.component';
import { OfferModalsHeaderComponent } from './modal-actions/offer-header/offer-modals-header.component';
import { ActionErrorComponent } from './modal-actions/action-error/action-error.component';
import { TranslocoRootModule } from 'smartauction-frontend-micro-library';

@NgModule({
  declarations: [
    EditCommentComponent,
    SaveCommentModalComponent,
    ModalPortalDirective,
    ActionSubmittingComponent,
    ActionSubmittedComponent,
    ModalCloseComponent,
    OfferModalsHeaderComponent,
    ActionErrorComponent
  ],
  imports: [
    CommonModule,
    ModalModule.forRoot(),
    PopoverModule.forRoot(),
    RemarketingUiComponentsLibModule,
    FormsModule,
    ReactiveFormsModule,
    TranslocoRootModule
  ],
  exports: [
    EditCommentComponent,
    SaveCommentModalComponent,
    ModalPortalDirective,
    ActionSubmittingComponent,
    ActionSubmittedComponent,
    ActionErrorComponent,
    ModalCloseComponent,
    OfferModalsHeaderComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CommentModule {}

import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-action-submitted',
  templateUrl: './action-submitted.component.html',
  styleUrls: ['./action-submitted.component.scss']
})
export class ActionSubmittedComponent implements AfterViewInit {
  @Input() actionText: string;
  @Input() detailText: string;
  @Input() tenantName: string;
  @Input() custom = false;
  @Output() close = new EventEmitter<void>();
  @ViewChild('heading') heading: ElementRef<HTMLElement>;

  constructor() {
    this.detailText = '';
  }

  cancel() {
    this.close.emit();
  }

  ngAfterViewInit(): void {
    this.heading.nativeElement.focus();
  }

  identifier(text): string {
    return 'cb_' + text.toLowerCase().replace(/ /g,'_');
  }

  showBoldedDetailText() : boolean {
    return !this.custom && this.detailText.length > 0;
  }

  showCustomText() : boolean {
    return this.custom && this.detailText.length > 0;
  }

}

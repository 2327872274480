import { Component } from '@angular/core';
import { WizardComponent } from 'angular-archwizard';

@Component({
  selector: 'app-registration-navigation',
  templateUrl: './registration-navigation.component.html',
  styleUrls: ['./registration-navigation.component.scss']
})
export class RegistrationNavigationComponent {

  constructor(private wizard: WizardComponent) { }

  public get currentStep() {
    return this.wizard.currentStepIndex + 1;
  }
  public get totalSteps() {
    return this.steps.length;
  }
  public get steps() {
    return this.wizard.wizardSteps;
  }
  public stepStatus(step) {
    if (step.selected) {
      return 'Current';
    } else if (step.completed) {
      return 'Completed';
    }
    return 'Inactive';
  }
  public stepAriaLabel(i, title, step) {
    return `Step ${(i + 1)}: ${title} - ${this.stepStatus(step)} Step`;
  }
}



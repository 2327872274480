<app-offer-modals-header heading="" [tenantName]="tenantName"></app-offer-modals-header>
<div class="action action-submitted text-centered" *transloco="let convert;">
  <div class="action-submitted__heading" tabindex="-1" #heading aria-live="polite">
    <i class="allycon-checkmark indicator-success me-3"></i>
    <span class="action-submitted__tagline fw-bold" id="action_confirmation_text">{{ actionText }}</span>
  </div>
  <div class="mb-3">
    <h5
      *ngIf="showBoldedDetailText()"
      [id]="identifier(detailText)"
      class="text-center col-md-12">
      {{ detailText }}
    </h5>
    <p
      *ngIf="showCustomText()"
      [innerHtml]="detailText"
      [id]="identifier(detailText)"
      class="text-center col-md-12">
    >
    </p>
  </div>

  <div class="action-submitted__actions">
    <div class="modal__actions">
      <button
        class="btn btn-primary mb-2"
        type="button"
        (click)="cancel()"
        data-track-elem="button"
        data-track-name="Close"
        data-track-trigger="close"
        id="action_confirmation_close_button">
        {{convert('common.btnClose')}}
      </button>
    </div>
  </div>
</div>
<app-modal-close
  (close)="cancel()"
  [identifier]="'action_confirmation_close_x_button'">
</app-modal-close>

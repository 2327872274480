import { Component, ViewChild } from '@angular/core';
import { ModalComponent } from 'remarketing-ui-components-lib';
import { uniqueId } from 'lodash';

@Component({
  selector: 'app-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.scss']
})
export class ErrorModalComponent {
  @ViewChild(ModalComponent) readonly modal: ModalComponent;
  message: string;
  btnCloseAlignCenter: boolean;
  readonly id = uniqueId('error-modal-');

  show() {
    this.modal.show();
  }

  close() {
    this.modal.close();
  }
}

<trap-focus [enableFocusTrap]="true" [enableArrowKeys]="true">
  <app-modal [modalId]="id">
    <div class="modal-close-container text-end">
      <button
        id="bso-accept-close"
        class="modal__close"
        aria-label="Close modal"
        (click)="close()"
        data-track-elem="icon"
        data-track-name="close"
        data-track-trigger="close"
      >
        <i class="allycon-glyph-close text-primary" aria-hidden="true"></i>
      </button>
    </div>
    <div class="modal__content mt-2 text-start" *transloco="let convert">
      <p tabindex="-1" [id]="id + '-title'" class="d-flex mb-4">
        <span class="text-danger me-2 float-left" aria-hidden="true"><app-error-icon fill="currentColor"></app-error-icon></span>
        <span class="message-text" [innerHTML]="message"></span>
      </p>
      <button class="btn btn-primary" [ngClass]="{'d-block mx-auto': btnCloseAlignCenter}" (click)="close()" appTrackButton="Close">{{ convert('common.btnClose') }}</button>
    </div>
  </app-modal>
</trap-focus>

import { Component, OnInit, ViewChild, ChangeDetectorRef, Input, OnDestroy } from '@angular/core';
import { UntypedFormControl, Validators, UntypedFormGroup, NgForm } from '@angular/forms';
import { first, get, template, isEmpty, chain } from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  Organization,
  OrganizationService,
  AnalyticsService,
  Configuration,
  ConfigurationStateModel,
  ConfigurationState,
  LaunchDarklyService,
  IProvince,
  IState,
  StatesProvincesService
} from 'remarketing-angular-library';
import { merge, Observable, Subject } from 'rxjs';
import { finalize, map, takeUntil } from 'rxjs/operators';
import { RegistrationStateService } from '../../services/registration-state/registration-state.service';
import { RegistrationWizardStep } from '../../interfaces/regisration-wizard-step.interface';
import { Store } from '@ngxs/store';
import { WizardComponent } from 'angular-archwizard';
import { TranslocoService } from '@ngneat/transloco';

export type SearchCriteriaViewSegment = keyof {
  pdn;
  dealershipName;
};
export namespace SearchCriteriaViewSegment {
  export const pdn: SearchCriteriaViewSegment = 'pdn';
  export const dealershipName: SearchCriteriaViewSegment = 'dealershipName';
}

@Component({
  selector: 'app-registration-step1',
  templateUrl: './registration-step1.component.html',
  styleUrls: ['./registration-step1.component.scss']
})
export class RegistrationStep1Component implements OnInit, OnDestroy, RegistrationWizardStep {
  private readonly destroyed$ = new Subject();

  @ViewChild('ngForm', {static: true}) ngForm: NgForm;
  @ViewChild('dealershipFormSubmit')
    dealershipFormSubmit;
  configuration: Configuration;
  readonly CONSTANTS = {
    stepTitle: '',
    title: '',
    dealershipName: '',
    state: '',
    transactionType: '',
    province: '',
    searchBy: '',
    resultSingular: '',
    resultPlural: '',
    resultForPdn: 'for PDN ${ pdn }',
    resultInstructionsFirstPart: '',
    resultInstructionsLastPart: '',
    noSelectionErrorMessage: '',
    errorMessages: {
      pdn: {
        required: 'Enter PDN to continue.',
        pattern: 'The PDN you entered doesn’t match our records. Try again.',
        minlength: 'The PDN you entered doesn’t match our records. Try again.',
        notFound: 'The PDN you entered doesn’t match our records. Try again.'
      },
      dealershipName: {
        required: '',
        pattern: '',
        minlength: '',
        notFoundFirstPart: '',
        notFoundLastPart: ''
      },
      selectedState: {
        required: ''
      },
      dealershipPdn: {
        required: ''
      },
      services: {
        notFoundPdn: 'The PDN you entered doesn\'t match our records. Try again.',
        notFoundDealershipName: 'The dealership name you entered doesn’t match our records. Try again.'
      },
      transactionType: {
        required: ''
      }
    },
    button: {
      add: '',
      cancel: '',
      continue: ''
    },
    wizard: {
      cancel: ''
    },
    selectOne: ''
  };

  currentPage: number;
  itemsPerPage = 50;

  didAttemptContinue: boolean;
  hasAttemptedSubmitPdnSelection: boolean;

  searchByPdn: SearchCriteriaViewSegment = SearchCriteriaViewSegment.pdn;
  searchByDealershipName: SearchCriteriaViewSegment = SearchCriteriaViewSegment.dealershipName;

  userForm: UntypedFormGroup;

  dealershipForm: UntypedFormGroup;
  selectedState: UntypedFormControl;
  dealershipPdn: UntypedFormControl;
  transactionType: UntypedFormControl;
  dealershipName: UntypedFormControl;

  dealerships: Organization[];
  transactionTypes: any;
  isAmbassadorTypeSelected = false;

  @Input() current: boolean;

  private readonly patternDealershipName = '[^~"^|;]*$';

  get isStepValid(): boolean {
    if (this.isAmbassadorTypeSelected) {
      return this.transactionType.valid;
    } else {
      return this.dealershipName.valid && this.selectedState.valid;
    }
  }

  get dealershipsTotal(): number {
    return this.dealerships.length;
  }
  get hasDealerships(): boolean {
    return this.dealershipsTotal > 0;
  }
  get hasSingleDealership(): boolean {
    return this.dealershipsTotal == 1;
  }

  get paginatedDealerships(): Organization[] {
    const begin = (this.currentPage - 1) * this.itemsPerPage;
    const end = begin + this.itemsPerPage;
    return this.dealerships.slice(begin, end);
  }

  get statesProvincesList(): IState[] | IProvince[] {
    return this.provincesEnabled ? this.statesProvincesService.getCAProvinces() : this.statesProvincesService.getUSStates();
  }

  get isDealershipNameInvalid(): boolean {
    return this.ngForm.submitted && this.dealershipName.invalid;
  }
  get isSelectedStateInvalid(): boolean {
    return this.ngForm.submitted && this.selectedState.invalid;
  }
  get isDealershipPdnInvalid(): boolean {
    return this.hasDealerships && this.dealershipPdn.invalid;
  }

  get isDealershipSelected(): boolean {
    return get(this.dealershipPdn, 'value') || false;
  }

  get resultsDisplayText(): string {
    const isPlural = this.dealerships.length > 1 || !this.dealerships.length;
    const tmplName = isPlural ? 'resultPlural' : 'resultSingular';
    return template(this.CONSTANTS[tmplName])({ n: this.dealerships.length });
  }

  get displayLabelContinueBtn(): string {
    const key = this.hasDealerships ? 'continue' : 'continue';
    return get(this.CONSTANTS, `button.${key}`);
  }

  get isPaginationVisible(): boolean {
    const hasMultiplePages = this.dealerships.length > this.itemsPerPage;
    return this.isStepValid && this.hasDealerships && hasMultiplePages;
  }

  get isInvalidDealershipNameNotFound(): boolean {
    return this.ngForm.submitted && !this.hasDealerships && !this.isDealershipNameInvalid;
  }
  get isInvalidDealershipNameRequired(): boolean {
    return this.isDealershipNameInvalid && get(this.dealershipName, 'errors.required');
  }
  get isInvalidDealershipNameMatchPattern(): boolean {
    return this.isDealershipNameInvalid && get(this.dealershipName, 'errors.pattern');
  }
  get isInvalidDealershipNameMinLength(): boolean {
    return this.isDealershipNameInvalid && get(this.dealershipName, 'errors.minlength');
  }
  get isInvalidDealershipNameMaxLength(): boolean {
    return this.isDealershipNameInvalid && get(this.dealershipName, 'errors.maxlength');
  }

  get isInvalidSelectedStateRequired(): boolean {
    return this.isSelectedStateInvalid && get(this.selectedState, 'errors.required');
  }

  get isInvalidDealershipPdnRequired(): boolean {
    return this.isDealershipPdnInvalid && get(this.dealershipPdn, 'errors.required');
  }

  get isInvalidWithoutPdnSelection(): boolean {
    return this.hasAttemptedSubmitPdnSelection && !this.isDealershipSelected;
  }

  get isPristine(): boolean {
    return chain(this)
      .pick('dealershipName', 'selectedState')
      .map(control => isEmpty(control.value))
      .reduce((isPristine, state) => isPristine && state)
      .value();
  }

  get provincesEnabled(): boolean {
    return this.launchDarklyService.getFeatureFlag('provincesEnabled', false);
  }

  get isTransactionTypeInvalid(): boolean {
    return this.ngForm.submitted && this.transactionType.invalid;
  }

  get showTransactionType(): boolean {
    return this.launchDarklyService.getFeatureFlag('userRegistration-auctionAccessId-100MEnforceEnabled', false);
  }

  onTransactionTypeChange(value:any) {
    if (value.target.selectedIndex == 4) {
      this.isAmbassadorTypeSelected = true;
      this.selectedState.reset();
      this.dealershipName.reset();
      this.dealershipPdn.reset();
    } else {
      this.isAmbassadorTypeSelected = false;
    }
  }

  constructor(
    private ref: ChangeDetectorRef,
    private wizard: WizardComponent,
    private spinner: NgxSpinnerService,
    private statesProvincesService: StatesProvincesService,
    private organizationService: OrganizationService,
    private registrationState: RegistrationStateService,
    private analyticsService: AnalyticsService,
    private store: Store,
    private readonly translocoService: TranslocoService,
    private readonly launchDarklyService: LaunchDarklyService
  ) {}

  ngOnInit() {
    this.getTranslations();
    this.store.select(ConfigurationState.configuration).pipe(takeUntil(this.destroyed$)).subscribe(configuration => {
      if (configuration) {
        this.configuration = configuration;
      }
    });
    this.registrationState.reset();
    this.buildForm();
    this.resetSearchResults();
    this.ref.detectChanges();

    merge(this.dealershipName.valueChanges, this.selectedState.valueChanges).pipe(takeUntil(this.destroyed$)).subscribe(() => this.resetSearchResults());
  }

  handleSubmit(): void {
    this.dealershipFormSubmit.nativeElement.click();
    if (!this.dealershipForm.valid) {
      setTimeout(() => {
        const error = document.getElementsByClassName('is-invalid') as HTMLCollectionOf<HTMLElement>;
        if (error.length) {
          error[0].focus();
        }
      }, 200);
    }
  }

  handleContinue(): void {
    if(!this.isAmbassadorTypeSelected){
      if (this.isStepValid && !this.hasDealerships) {
        this.handlePdnSearch();
      } else if (this.isStepValid) {
        this.handlePdnSelection();
      }
    } else {
      this.registrationState.isAmbassador = true;
      this.wizard.goToNextStep();
    }

    if (this.hasDealerships) {
      const errors = [];
      if (this.isDealershipNameInvalid) {
        errors.push(this.CONSTANTS.errorMessages.dealershipName);
      }
      if (this.isSelectedStateInvalid) {
        errors.push(this.CONSTANTS.errorMessages.selectedState);
      }

      this.analyticsService.trackEndpoint({
        formValues: {
          name: 'Registration Whitelabel Dealership Info',
          status: this.isStepValid ? 'success' : 'error',
          errors: errors,
          type: '',
          step: '1',
          stepname: '',
          items: [
            {
              allow: 'yes',
              error: this.isDealershipNameInvalid ? this.CONSTANTS.errorMessages.dealershipName : '',
              name: 'Dealership Name',
              track: 'yes',
              value: this.paginatedDealerships.find(dealership => dealership.internalId === this.dealershipPdn.value)
                .oeName
            },
            {
              allow: 'yes',
              error: this.isSelectedStateInvalid ? this.CONSTANTS.errorMessages.selectedState : '',
              name: 'State',
              track: 'yes',
              value: this.selectedState.value
            },
            {
              allow: 'yes',
              error: this.isTransactionTypeInvalid ? this.CONSTANTS.errorMessages.transactionType : '',
              name: 'Transaction Type',
              track: 'yes',
              value: this.transactionType.value
            }
          ]
        }
      });
    }
  }

  canCompleteStep(): boolean {
    if(this.isAmbassadorTypeSelected) {
      return this.isStepValid;
    } else {
      return this.isStepValid && this.dealershipPdn.valid;
    }
  }

  private buildForm(): void {
    this.selectedState = new UntypedFormControl('', Validators.required);
    this.dealershipPdn = new UntypedFormControl('', Validators.required);
    this.transactionType = new UntypedFormControl('', Validators.required);

    this.dealershipName = new UntypedFormControl(
      '',
      Validators.compose([
        Validators.required,
        Validators.pattern(this.patternDealershipName),
        Validators.minLength(3),
        Validators.maxLength(50)
      ])
    );
    this.dealershipForm = new UntypedFormGroup({
      dealershipName: this.dealershipName,
      selectedState: this.selectedState,
      dealershipPdn: this.dealershipPdn,
      transactionType: this.transactionType
    });
  }

  private resetSearchResults(): void {
    this.currentPage = 1;
    this.dealerships = [];
    this.dealershipPdn.setValue(null);
    this.didAttemptContinue = false;
    this.hasAttemptedSubmitPdnSelection = false;
  }

  handlePdnSearch(): void {
    this.spinner.show();

    const dealershipName = this.dealershipName.value;
    const state = this.selectedState.value;
    const tenantUuid = get(this.configuration, 'tenantUuid');
    this.organizationService
      .getOrganizationsUsingPublicApi(tenantUuid, dealershipName, state)
      .pipe(
        takeUntil(this.destroyed$),
        map(resp => resp['data']),
        finalize(() => this.spinner.hide())
      )
      .subscribe(
        (organizations: Organization[]) => this.handlePdnSearchResponse(organizations),
        err => console.log('error', err)
      );
  }

  handlePdnSearchResponse(organizations: Organization[]): void {
    this.dealerships = organizations;
    this.didAttemptContinue = true;

    if (this.hasSingleDealership) {
      this.dealershipPdn.setValue(first<Organization>(this.dealerships).internalId);
    }
    // focus on results text or error
    setTimeout(() => {
      if (this.isInvalidWithoutPdnSelection) {
        const error = document.getElementById('dealershipPdn-error') as HTMLElement;
        error?.focus();
      } else {
        const main = document.getElementById('resultsDisplayText') as HTMLElement;
        main?.focus();
      }
    }, 200);
  }

  private handlePdnSelection(): void {
    if (!this.isDealershipSelected) {
      this.hasAttemptedSubmitPdnSelection = true;
      // focus on results error
      setTimeout(() => {
        const error = document.getElementById('dealershipPdn-noSelectionError') as HTMLElement;
        error.focus();
      }, 200);
      return;
    }

    this.registrationState.dealerId = this.dealershipPdn.value;
    this.wizard.goToNextStep();
  }

  getTranslations(){
    this.translocoService.selectTranslateObject('registration.registrationStep1').pipe(takeUntil(this.destroyed$)).subscribe(translate => {
      this.CONSTANTS.stepTitle = translate.stepTitle;
      this.CONSTANTS.title = translate.title;
      this.CONSTANTS.dealershipName = translate.dealershipName;
      this.CONSTANTS.transactionType = translate.transactionType;
      this.transactionTypes = translate.transactionTypes;
      this.CONSTANTS.state = translate.state;
      this.CONSTANTS.province = translate.province;
      this.CONSTANTS.searchBy = translate.searchBy;
      this.CONSTANTS.resultSingular = translate.resultSingular;
      this.CONSTANTS.resultPlural = translate.resultPlural;
      this.CONSTANTS.resultInstructionsFirstPart = translate.resultInstructionsFirstPart;
      this.CONSTANTS.resultInstructionsLastPart = translate.resultInstructionsLastPart;
      this.CONSTANTS.noSelectionErrorMessage = translate.noSelectionErrorMessage;
      this.CONSTANTS.errorMessages.dealershipName.required = translate.errorMessages.dealershipName.required;
      this.CONSTANTS.errorMessages.dealershipName.pattern = translate.errorMessages.dealershipName.pattern;
      this.CONSTANTS.errorMessages.dealershipName.minlength = translate.errorMessages.dealershipName.minlength;
      this.CONSTANTS.errorMessages.dealershipName.notFoundFirstPart = translate.errorMessages.dealershipName.notFoundFirstPart;
      this.CONSTANTS.errorMessages.dealershipName.notFoundLastPart = translate.errorMessages.dealershipName.notFoundLastPart;
      this.CONSTANTS.errorMessages.selectedState.required = translate.errorMessages.selectedState.required;
      this.CONSTANTS.errorMessages.dealershipPdn.required = translate.errorMessages.dealershipPdn.required;
      this.CONSTANTS.errorMessages.transactionType = translate.errorMessages.transactionType;
      this.CONSTANTS.wizard.cancel = translate.wizard.cancel;
      this.CONSTANTS.selectOne = translate.selectOne;
    });

    this.translocoService.selectTranslateObject('common').pipe(takeUntil(this.destroyed$)).subscribe(translate => {
      this.CONSTANTS.button.add = translate.btnAdd;
      this.CONSTANTS.button.cancel = translate.btnCancel;
      this.CONSTANTS.button.continue = translate.btnContinue;
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}

import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { FeatureService } from '../services/feature/feature.service';

@Injectable({
  providedIn: 'root'
})
export class FeatureGuard implements CanActivate {
  constructor(private featureService: FeatureService) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    return this.featureService.enabled(route.data.featureName);
  }
}

import { Component, EventEmitter, Output, Input } from '@angular/core';

@Component({
  selector: 'app-modal-close',
  template: `
    <div class="modal-close-container" *transloco="let convert;">
      <button
        class="modal__close"
        [attr.aria-label]="convert('common.modalClose')"
        (click)="close.emit()"
        data-track-elem="icon"
        data-track-name="Close"
        data-track-trigger="close"
        [id]="identifier"
      >
        <i class="allycon-glyph-close text-primary" aria-hidden="true"></i>
      </button>
    </div>
  `,
  styleUrls: ['./modal-close.component.scss']
})
export class ModalCloseComponent {
  @Output() close = new EventEmitter();
  @Input() identifier = '';
}

import { UntypedFormControl } from '@angular/forms';

export function MatchValuesValidator(controlName: string) {

  let thisControl: UntypedFormControl;
  let otherControl: UntypedFormControl;

  return (control: UntypedFormControl) => {

    if (!control.parent) return null;

    if (!thisControl) {
      thisControl = control;
      otherControl = control.parent.get(controlName) as UntypedFormControl;

      if (!otherControl) {
        throw new Error(`MatchValuesValidator(): "${controlName}" isn't found in parent group of controls.`);
      }

      otherControl.valueChanges.subscribe(() => thisControl.updateValueAndValidity());
    }

    if (!otherControl) return null;

    if (otherControl.value !== thisControl.value) {
      return { matchValues: true };
    }

    return null;
  };
}

import { Component, ViewChild, HostListener, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { RegistrationStep1Component } from '../registration-step1/registration-step1.component';
import { RegistrationStep2Component } from '../registration-step2/registration-step2.component';
import { RegistrationStep3Component } from '../registration-step3/registration-step3.component';
import { RegistrationCancelModalComponent } from '../registration-cancel-modal/registration-cancel-modal.component';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { BehaviorSubject, Subject } from 'rxjs';
import { AnalyticsService } from 'smartauction-frontend-micro-library';
import { chain } from 'lodash';
import { MovingDirection, WizardComponent } from 'angular-archwizard';

@Component({
  selector: 'app-registration-wizard',
  templateUrl: './registration-wizard.component.html',
  styleUrls: ['./registration-wizard.component.scss']
})
export class RegistrationWizardComponent implements OnDestroy {
  private readonly destroyed$ = new Subject();
  private canDeactivate$ = new BehaviorSubject<boolean>(false);
  private modalRef: BsModalRef;

  @ViewChild(WizardComponent)
    wizard: WizardComponent;

  @ViewChild(RegistrationStep1Component)
    registrationStep1Component: RegistrationStep1Component;

  @ViewChild(RegistrationStep2Component)
    registrationStep2Component: RegistrationStep2Component;

  @ViewChild(RegistrationStep3Component)
    registrationStep3Component: RegistrationStep3Component;

  current = 'step1Header';

  get isDeactivatable(): boolean {
    if (this.registrationStep3Component.formSubmitSuccessful) {
      return true;
    }

    return chain(this)
      .pick('registrationStep1Component', 'registrationStep2Component', 'registrationStep3Component')
      .map(component => component.isPristine)
      .reduce((isPristine, state) => isPristine && state)
      .value();
  }

  constructor(
    private modalService: BsModalService,
    private analyticsService: AnalyticsService,
    private route: Router
  ) { }

  @HostListener('window:beforeunload')
  public canDeactivate(): BehaviorSubject<boolean> {
    if (this.isDeactivatable) {
      this.canDeactivate$.next(true);
    }
    else if (!this.canDeactivate$.getValue()) {
      this.promptCancelDialog();
    }

    return this.canDeactivate$;
  }

  canContinue: (MovingDirection) => boolean = direction => {
    if (direction === MovingDirection.Backwards) {
      return true;
    }

    switch (this.wizard.currentStepIndex) {
    case 0:
      return this.registrationStep1Component.canCompleteStep();
    case 1:
      return this.registrationStep2Component.canCompleteStep();
    case 2:
      return this.registrationStep3Component.canCompleteStep();
    }
  };

  willEnterStep(): void {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera

    switch (this.wizard.currentStepIndex) {
    // Choose Dealership
    case 0:
      this.analyticsService.trackPageLoad({
        page: {
          pageName: `${this.analyticsService.digitalData.page.category.site}:Admin:New User Registration Dealership Info:White Label`,
          section: 'Admin',
          site: this.analyticsService.digitalData.page.category.site,
          subsection: 'New User Registration Dealership Info',
          title: 'White Label'
        }
      });
      break;
      // User Information
    case 1:
      this.analyticsService.trackPageLoad({
        page: {
          pageName: `${this.analyticsService.digitalData.page.category.site}:Admin:New User Registration User Info:White Label`,
          section: 'Admin',
          site: this.analyticsService.digitalData.page.category.site,
          subsection: 'New User Registration User Info',
          title: 'White Label'
        }
      });
      break;
      // Create credentials
    case 2:
      this.analyticsService.trackPageLoad({
        page: {
          pageName: `${this.analyticsService.digitalData.page.category.site}:Admin:New User Registration Create Credentials:White Label`,
          section: 'Admin',
          site: this.analyticsService.digitalData.page.category.site,
          subsection: 'New User Registration Create Credentials',
          title: 'White Label'
        }
      });
      break;
    }
  }

  private promptCancelDialog(): void {
    this.modalRef = this.modalService.show(RegistrationCancelModalComponent, { class: 'modal-md modal-confirm' });
    this.modalRef.content.didConfirm$.subscribe(() => this.confirmCancel());
    this.modalRef.content.didDecline$.subscribe(() => this.declineCancel());
    this.modalService.onHide.subscribe(() => this.declineCancel());
  }

  private confirmCancel(): void {
    this.modalRef.hide();
    this.canDeactivate$.next(true);
    this.route.navigate(['/']);
  }

  private declineCancel(): void {
    this.modalRef.hide();
    this.canDeactivate$.next(false);
  }

  headerFocus(className) {
    this.current = className;
    setTimeout(() => {
      const heading = document.getElementsByClassName(className) as HTMLCollectionOf<HTMLElement>;
      heading[0].focus();
    }, 100);
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}

<header>
  <h1 tabindex="-1" class="step1Header" id="{{current ? 'main-content-focus': ''}}">{{ CONSTANTS.title }}</h1>
</header>

<form [formGroup]="dealershipForm" #ngForm="ngForm" id="dealership-form" class="step-content" (ngSubmit)="handleContinue()" autocomplete="off">
  <input #dealershipFormSubmit type="submit" value="dealershipFormSubmit" hidden />

  <div *ngIf="showTransactionType" class="form-group">
    <label for="transactionType" id="transactionType-label">{{ CONSTANTS.transactionType }}</label>
    <div class="control-wrapper select-wrapper col-md-6 col-lg-4 px-0">
      <select formControlName="transactionType" id="transactionType" (change)="onTransactionTypeChange($event)"class="custom-select col-sm-12 w-100" maxlength="50" [class.is-invalid]="isTransactionTypeInvalid" aria-required="true" aria-labelledby="transactionType-label transactionType-error">
        <option value="">{{ CONSTANTS.selectOne }}</option>
        <option *ngFor="let transactionType of transactionTypes" value="{{ transactionType.key }}">{{ transactionType.value }}</option>
      </select>
      <span *ngIf="isTransactionTypeInvalid && transactionType.errors?.required" class="invalid-feedback" id="transactionType-error_required">{{ CONSTANTS.errorMessages.transactionType.required }}</span>
    </div>
  </div>

  <div *ngIf="!isAmbassadorTypeSelected" class="form-group">
    <label for="dealershipName" class="control-label" id="dealershipName-label">{{ CONSTANTS.dealershipName }}</label>
    <div class="control-wrapper col-md-6 col-lg-4 px-0">
      <input aria-required="true" formControlName="dealershipName" id="dealershipName" maxlength="50" class="form-control col-sm-12" [class.is-invalid]="isDealershipNameInvalid || (didAttemptContinue && !hasDealerships)" autocomplete="off" aria-labelledby="dealershipName-label dealershipName-error_notFound dealershipName-error_required dealershipName-error_pattern dealershipName-error_length">
      <span *ngIf="isInvalidDealershipNameNotFound" class="invalid-feedback" aria-live="assertive" id="dealershipName-error_notFound">{{ CONSTANTS.errorMessages.dealershipName.notFoundFirstPart }}
        <a
        data-track-name="phone"
        [attr.data-track-elem]="configuration.header.contactInfo.phoneNumber | formatPhoneNumber"
        [href]="configuration.header.contactInfo.phoneNumber | telephoneLink"
        attr.aria-label="Phone: {{ configuration.header.contactInfo.phoneNumber | formatPhoneNumber }}"
        class="deskmenu-phone">
        {{ configuration.header.contactInfo.phoneNumber | formatPhoneNumber }}</a>
        {{ CONSTANTS.errorMessages.dealershipName.notFoundLastPart }}</span>
      <span *ngIf="isInvalidDealershipNameRequired" class="invalid-feedback" aria-live="assertive" id="dealershipName-error_required">{{ CONSTANTS.errorMessages.dealershipName.required }}</span>
      <span *ngIf="isInvalidDealershipNameMatchPattern" class="invalid-feedback" aria-live="asserive" id="dealershipName-error_pattern">{{ CONSTANTS.errorMessages.dealershipName.pattern }}</span>
      <span *ngIf="isInvalidDealershipNameMinLength" class="invalid-feedback" aria-live="assertive" id="dealershipName-error_length">{{ CONSTANTS.errorMessages.dealershipName.minlength }}</span>
    </div>
  </div>

  <div *ngIf="!isAmbassadorTypeSelected" class="form-group">
    <label for="selectedState" class="control-label" id="selectedState-label">{{ provincesEnabled ? CONSTANTS.province : CONSTANTS.state }}</label>
    <div class="control-wrapper select-wrapper col-md-6 col-lg-4 px-0">
      <select formControlName="selectedState" id="selectedState" class="custom-select w-100 col-sm-12" [class.is-invalid]="isSelectedStateInvalid" aria-required="true" aria-labelledby="selectedState-label selectedState-error">
        <option value="">{{ CONSTANTS.selectOne }}</option>
        <option *ngFor="let stateProvince of statesProvincesList" value="{{ stateProvince.abbr }}">{{ stateProvince.name }}</option>
      </select>

      <span *ngIf="isSelectedStateInvalid && selectedState.errors?.required" class="invalid-feedback" aria-live="assertive" id="selectedState-error">{{ CONSTANTS.errorMessages.selectedState.required }}</span>
    </div>
  </div>

  <!-- begin: list of dealerships -->
  <div *ngIf="!isAmbassadorTypeSelected && isStepValid && hasDealerships" id="list-dealerships" class="form-group group-offset list-dealerships mt-4" [class.has-single-dealership]="hasSingleDealership" [class.is-invalid]="isDealershipPdnInvalid">

    <div class="control-wrapper col-md-6 col-lg-4 px-0">
      <!-- Display No selection Error message -->
      <div *ngIf="isInvalidWithoutPdnSelection" class="border-0 text-danger fw-bold mb-2" aria-live="polite" id="dealershipPdn-noSelectionError" tabIndex="-1">
        <span class="allycon-error-fill"></span>&nbsp; {{ CONSTANTS.noSelectionErrorMessage }}
      </div>

      <h2 class="mb-0" id="resultsDisplayText" tabIndex="-1" >{{ resultsDisplayText }}</h2>
      <p class="mb-1 instruction-text-width" aria-live="polite">{{ CONSTANTS.resultInstructionsFirstPart }}
        <a
        data-track-name="phone"
        [attr.data-track-elem]="configuration.header.contactInfo.phoneNumber | formatPhoneNumber"
        [href]="configuration.header.contactInfo.phoneNumber | telephoneLink"
        attr.aria-label="Phone: {{ configuration.header.contactInfo.phoneNumber | formatPhoneNumber }}"
        class="deskmenu-phone">
        {{ configuration.header.contactInfo.phoneNumber | formatPhoneNumber }}</a> {{ CONSTANTS.resultInstructionsLastPart }}
      </p>
      <span *ngIf="isInvalidDealershipPdnRequired" class="invalid-feedback" aria-live="polite" id="dealershipPdn-error">{{ CONSTANTS.errorMessages.dealershipPdn.required }}</span>

      <div *ngFor="let dealership of paginatedDealerships" class="custom-control custom-radio">
        <input type="radio" formControlName="dealershipPdn" id="dealershipPdn-{{ dealership.internalId }}" value="{{ dealership.internalId }}" class="custom-control-input">
        <label class="custom-control-label" for="dealershipPdn-{{ dealership.internalId }}" id="dealershipPdn-{{ dealership.internalId }}-label">
          <span class="text-oeName">{{ dealership.oeName }}</span>
          {{ dealership.physicalAddress.addressLine1 }}, {{ dealership.physicalAddress.city }},
          {{ dealership.physicalAddress.state }} {{ dealership.physicalAddress.zip }}
        </label>
      </div>
    </div>
  </div>
  <!-- end: list of dealerships -->

  <!-- begin: pagination -->
  <div *ngIf="isPaginationVisible" class="form-group group-offset mt-4">
    <div class="control-wrapper">
      <pagination [totalItems]="dealershipsTotal"
                  [itemsPerPage]="itemsPerPage"
                  [(ngModel)]="currentPage"
                  [ngModelOptions]="{standalone: true}"
                  [maxSize]="5"
                  previousText="<i class='allycon-glyph-chevron-left'></i>"
                  nextText="<i class='allycon-glyph-chevron-right'></i>">
      </pagination>
    </div>
  </div>
</form>

<div class="mt-4 control-wrapper__actions">
  <button data-track-elem="button" [attr.data-track-name]="displayLabelContinueBtn" type="button" (click)="handleSubmit()" class="btn btn-primary" [appTrackButton]="displayLabelContinueBtn">{{ displayLabelContinueBtn }}</button>
  <button data-track-elem="button" [attr.data-track-name]="CONSTANTS.wizard.cancel" type="button" routerLink="/login" class="btn btn-link cancel-btn" [appTrack]="{name: CONSTANTS.wizard.cancel, trigger: 'interstital'}">{{ CONSTANTS.wizard.cancel }}</button>
</div>

<ng-container *transloco="let convert;">
<article class="modal-body" [appTrack]="{ region: 'Cancel Registration' }">
  <a data-track-elem="button" data-track-name="Close" data-track-trigger="close" (click)="handleDecline()"
    ><i class="allycon-glyph-close" [appTrack]="{ elem: 'icon', name: 'Close', trigger: 'close' }"></i
  ></a>
  <h1>{{convert('registration.registrationCancelModal.textDontSave')}}?</h1>
  <p>{{convert('registration.registrationCancelModal.textLeaveEdit')}}</p>

  <div class="button-container d-md-flex">
    <button data-track-elem="button" data-track-name="Don't Save Changes" type="button" class="btn btn-primary" (click)="handleConfirm()" [appTrack]="{elem: 'button', name: 'Don\'t Save Changes', trigger: 'external'}">
      {{convert('registration.registrationCancelModal.textDontSave')}}
    </button>
    <button data-track-elem="button" data-track-name="Go Back" type="button" class="btn btn-link btn-back" (click)="handleDecline()" [appTrack]="{elem: 'button', name: 'Don\'t Save Changes', trigger: 'close'}">{{convert('registration.registrationCancelModal.btnGoBack')}}</button>
  </div>
</article>
</ng-container>

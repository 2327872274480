import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ModalComponent } from 'remarketing-ui-components-lib';
import { SmsActivationRequestDto, SmsEnrollRequestDto, UserService } from 'remarketing-angular-library';
import { take, timeout } from 'rxjs/operators';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-sms-modal',
  templateUrl: './sms-modal.component.html',
  styleUrls: ['./sms-modal.component.scss']
})
export class SmsModalComponent implements OnInit {
  @ViewChild(ModalComponent) modal: ModalComponent;
  @Input() mobilePhone: string;
  @Input() tenantUuid: string;
  @Input() userId: string;
  @Output() mobileVerified = new EventEmitter<boolean>();

  private _currentView: string;
  private _errorMessage: string;
  private _oktaFactorId: string;
  private readonly VIEW = {
    CONFIRMATION: 'CONFIRMATION',
    ACTIVATE: 'ACTIVATE',
    SUCCESS: 'SUCCESS'
  };
  readonly ERROR_MESSAGES = {
    EMPTY_PASSCODE: '',
    PASSCODE_TIMEOUT: 'The security code expired.  Resend the security code to continue.',
    INVALID_PASSCODE: 'The security code you entered doesn\'t match our records.  Try again.',
    TOO_MANY_REQUESTS: ''
  };

  readonly API_ERRORS_MESSAGES = {
    INVALID_PASSCODE : 'Invalid Passcode',
    PASSCODE_TIMEOUT : 'Passcode Timeout',
    INVALID_TIMEOUT_COMMON_MSG_DESC : 'The security code you entered expired or doesn\'t match our records. Try again.',
    INVALID_TIMEOUT_COMMON_MSG_ERROR : 'Invalid or Expired Passcode'
  };
  readonly TOO_MANY_REQUESTS_TIME = 30000;
  readonly PASSCODE_TIMEOUT_TIME = 300000;

  passCode = '';
  modalId = 'sms-modal';

  serviceResponseTime:number = null;
  isResendBtnDisable = false;
  private _showError = false;

  get errorMessage(): string {
    return this._errorMessage;
  }

  showConfirmationView(): boolean {
    return this._currentView === this.VIEW.CONFIRMATION;
  }

  showActivateView(): boolean {
    return this._currentView === this.VIEW.ACTIVATE;
  }

  showSuccessView(): boolean {
    return this._currentView === this.VIEW.SUCCESS;
  }

  get maskedPhoneNumber(): string {
    return this.mobilePhone ? `1-XXX-XXX-${this.mobilePhone.substring(this.mobilePhone.length - 4)}` : '';
  }

  showError(): boolean {
    return this._showError;
  }

  constructor(
    private readonly _userService: UserService,
    private readonly translocoService: TranslocoService
  ) { }

  ngOnInit() {
    this.getTranslations();
    this._currentView = this.VIEW.CONFIRMATION;
    this._errorMessage = this.ERROR_MESSAGES.TOO_MANY_REQUESTS;
  }

  show() {
    this._currentView = this.VIEW.CONFIRMATION;
    this.passCode = '';
    this.modal.show();
  }

  close(): void {
    this.mobileVerified.emit(false);
    this.modal.close();
  }

  confirmation() {
    this._currentView = this.VIEW.ACTIVATE;
    this.serviceResponseTime = null;
    this.enrollSms();
  }

  activateSms() {
    if (!this.hasPassCode()) {
      this._errorMessage = this.ERROR_MESSAGES.EMPTY_PASSCODE;
      this._showError = true;
    } else {
      this._showError = false;
      this._userService.activateSms(this.buildActivateSmsRequest()).pipe(take(1)).subscribe(() => {
        this._currentView = this.VIEW.SUCCESS;
      }, error => {
        if (error.status == 500 && error.error.error === this.API_ERRORS_MESSAGES.INVALID_TIMEOUT_COMMON_MSG_ERROR) {
          this._errorMessage = this.API_ERRORS_MESSAGES.INVALID_TIMEOUT_COMMON_MSG_DESC;
        } else {
          this._errorMessage = error.error.error_description;
        }
        this._showError = true;
      });
    }
  }

  enrollSms() {
    this._showError = false;
    this.isResendBtnDisable = true;
    this._userService.enrollSms(this.buildEnrollSmsRequest()).pipe(take(1)).subscribe(response => {
      this._oktaFactorId = response.oktaFactorId;
      if (response.status === 'ACTIVE') {
        this._currentView = this.VIEW.SUCCESS;
      } else {
        this.serviceResponseTime = new Date().getTime();
        setTimeout(() => {
          this.isResendBtnDisable = false;
        }, this.TOO_MANY_REQUESTS_TIME);
      }
    }, error => {
      if (/A factor of this type is already set up./.test(error.error.errors[0].detail)) {
        this._currentView = this.VIEW.SUCCESS;
      } else {
        this._errorMessage = error.error.error_description;
        this._showError = true;
      }
    });
  }

  private buildActivateSmsRequest(): SmsActivationRequestDto {
    return {
      oktaFactorId: this._oktaFactorId,
      passCode: this.passCode,
      tenantUuid: this.tenantUuid,
      userId: this.userId,
    };
  }

  private buildEnrollSmsRequest(): SmsEnrollRequestDto {
    return {
      mobilePhone: this.mobilePhone,
      tenantUuid: this.tenantUuid,
      userId: this.userId
    };
  }

  private hasPassCode(): boolean {
    return this.passCode && this.passCode.length > 0;
  }

  public getErrorStatus() {
    return (this._showError === true) ? 'input-error' : '';
  }

  getTranslations(){
    this.translocoService.selectTranslateObject('shared.smsModal').subscribe(translate => {
      this.ERROR_MESSAGES.EMPTY_PASSCODE = translate.textEmtyPasscode;
      this.ERROR_MESSAGES.TOO_MANY_REQUESTS = translate.textToRequest;
    });
  }
}

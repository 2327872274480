import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-action-error',
  templateUrl: './action-error.component.html',
  styleUrls: ['./action-error.component.scss']
})
export class ActionErrorComponent implements AfterViewInit {
  @Input() errorText: string;
  @Input() tenantName: string;
  @Output() close = new EventEmitter<void>();
  @ViewChild('heading') heading: ElementRef<HTMLElement>;
  closeModal() {
    this.close.emit();
  }

  ngAfterViewInit(): void {
    this.heading.nativeElement.focus();
  }
}

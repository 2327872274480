import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-registration-container',
  templateUrl: './registration-container.component.html',
  styleUrls: ['./registration-container.component.scss']
})
export class RegistrationContainerComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}

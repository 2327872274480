import { Directive } from '@angular/core';
import { Route } from '@angular/router';

import { AuthGuard, LaunchDarklyUserGuard, RoleBasedGuard } from 'remarketing-angular-library';

import { FeatureGuard } from './guards/feature.guard';
import { AgreementsGuard, GUARDS } from 'smartauction-frontend-micro-library';

export enum RoutePaths {
  PROFILE_MANAGEMENT = 'profile-management',
  REGISTRATION = 'register',
  SEARCH = 'search',
  SUPPORT_PAGE = 'support',
  SELLING_DISCLOSURES = 'selling-disclosures',
  SUMMARY_DISCLOSURES = 'summary-disclosures',
  VEHICLES = 'vehicles',
  TRANSPORTATION_HUB = 'transportation',
  SELL = 'inventory',
  OFFER_LIST = 'offer-list',
  PURCHASE = 'purchase/auction-summary',
  AUCTION_SUMMARY = 'auction-summary',
  TERMS_CONDITIONS = 'smsterms',
  AMBASSADOR = 'ambassador-home',
  AMBASSADOR_AUTHORIZATION = 'ambassador-authorization',
  DASHBOARD = 'dashboard',
  MESSAGES = 'messages',
  UNSUBSCRIBE = 'email-unsubscribe',
  LOGIN = 'login',
  ENROLL = 'enroll',
  DEALER_ONBOARDING = 'dor'
}

@Directive()
export class AppRoute {
  static PROFILE_MANAGEMENT: Route = {
    path: RoutePaths.PROFILE_MANAGEMENT,
    loadChildren: () => import('./modules/profile-management/wrapper.module').then(m => m.ProfileManagementWrapperModule),
    canActivate: [AuthGuard]
  };

  static REGISTRATION: Route = {
    path: RoutePaths.REGISTRATION,
    loadChildren: () => import('./modules/registration/registration.module').then(m => m.RegistrationModule)
  };

  static SEARCH: Route = {
    path: RoutePaths.SEARCH,
    loadChildren: () => import('./modules/search/wrapper.module').then(m => m.SearchWrapperModule),
    canActivate: [AuthGuard, RoleBasedGuard],
    data: { roles: ['View_Only'] }
  };

  static SUPPORT_PAGE: Route = {
    path: RoutePaths.SUPPORT_PAGE,
    loadChildren: () => import('./modules/supportpage/supportpage.module').then(m => m.SupportpageModule),
    canActivate: [AuthGuard]
  };

  static SELLING_DISCLOSURES: Route = {
    path: RoutePaths.SELLING_DISCLOSURES,
    loadChildren: () => import('./modules/sellingdisclosures/sellingdisclosures.module').then(m => m.SellingdisclosuresModule),
    canActivate: [AuthGuard, RoleBasedGuard],
    data: { roles: ['View_Only'] }
  };

  static SUMMARY_DISCLOSURES: Route = {
    path: RoutePaths.SUMMARY_DISCLOSURES,
    loadChildren: () => import('./modules/summarydisclosures/summarydisclosures.module').then(m => m.SummarydisclosuresModule),
    canActivate: [AuthGuard, RoleBasedGuard],
    data: { roles: ['View_Only'] }
  };

  static VEHICLES: Route = {
    path: RoutePaths.VEHICLES,
    loadChildren: () => import('./modules/vehicles/wrapper.module').then(m => m.VehiclesWrapperModule),
    canActivate: [AuthGuard, RoleBasedGuard],
    data: { roles: ['View_Only'] }
  };

  static TRANSPORTATION_HUB: Route = {
    path: RoutePaths.TRANSPORTATION_HUB,
    loadChildren: () => import('./modules/transportation-hub/wrapper.module').then(m => m.TransportationHubWrapperModule),
    canActivate: [AuthGuard, LaunchDarklyUserGuard, RoleBasedGuard],
    data: {
      flag: 'transportation-transportationHubEnabled',
      roles: ['View_Only']
    }
  };

  static SELL: Route = {
    path: RoutePaths.SELL,
    loadChildren: () => import('./modules/sell/sell.module').then(m => m.SellWrapperModule),
    canActivate: [
      AuthGuard,
      AgreementsGuard,
      FeatureGuard,
      RoleBasedGuard
    ],
    data: {
      featureName: 'sell',
      roles: ['View_Only'],
      guards: [
        GUARDS.TermsOfUseGuard,
        GUARDS.PrivacyPolicyGuard
      ]
    }
  };

  static OFFER_LIST: Route =    {
    path: RoutePaths.OFFER_LIST,
    loadChildren: () => import('./modules/offer-list/wrapper.module').then(m => m.OfferListWrapperModule),
    canActivate: [AuthGuard, RoleBasedGuard],
    data: { roles: ['View_Only'] }
  };

  static AUCTION_SUMMARY: Route = {
    path: RoutePaths.PURCHASE,
    loadChildren: () => import('./modules/auction-summary/wrapper.module').then(m => m.AuctionSummaryWrapperModule),
    canActivate: [AuthGuard, RoleBasedGuard],
    data: { roles: ['View_Only'] }
  };

  static TERMS_CONDITIONS: Route = {
    path: RoutePaths.TERMS_CONDITIONS,
    loadChildren: () => import('./modules/terms-conditions/wrapper.module').then(m => m.TermsConditionsWrapperModule),
  };

  static AMBASSADOR: Route = {
    path: RoutePaths.AMBASSADOR,
    loadChildren: () => import('./modules/ambassador/wrapper.module').then(m => m.AmbassadorWrapperModule)
  };

  static AMBASSADOR_AUTHORIZATION: Route = {
    path: RoutePaths.AMBASSADOR_AUTHORIZATION,
    loadChildren: () => import('./modules/ambassador-authorization/wrapper.module').then(m => m.AmbassadorAuthorizationWrapperModule)
  };

  static MESSAGES: Route = {
    path: RoutePaths.MESSAGES,
    loadChildren: () => import('./modules/messages/wrapper.module').then(m => m.MessagesWrapperModule),
    canActivate: [RoleBasedGuard],
    data: { roles: ['View_Only'] }
  };

  static DASHBOARD: Route = {
    path: RoutePaths.DASHBOARD,
    loadChildren: () => import('./modules/dashboard/wrapper.module').then(m => m.DashboardWrapperModule),
    canActivate: [AuthGuard],
    canDeactivate: [RoleBasedGuard]
  };

  static UNSUBSCRIBE: Route = {
    path: RoutePaths.UNSUBSCRIBE,
    loadChildren: () => import('./modules/unsubscribe/wrapper.module').then(m => m.UnsubscribeWrapperModule),
  };

  static DEALER_ONBOARDING_REGISTRATION: Route = {
    path: RoutePaths.DEALER_ONBOARDING,
    loadChildren: () => import('./modules/dealer-onboarding/wrapper.module').then(m => m.DealerOnboardingWrapperModule)
  };
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PendingChangesGuard } from '../../guards/pending-changes.guard';

import { RegistrationContainerComponent } from './components/registration-container/registration-container.component';
import { RegistrationWizardComponent } from './components/registration-wizard/registration-wizard.component';
import { RegistrationConfirmationComponent } from './components/registration-confirmation/registration-confirmation.component';

const routes: Routes = [
  {
    path: '',
    component: RegistrationContainerComponent,
    children: [
      { path: '', component: RegistrationWizardComponent, canDeactivate: [PendingChangesGuard] },
      { path: 'confirmation', component: RegistrationConfirmationComponent },
    ]
  },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RegistrationRoutingModule { }

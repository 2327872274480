import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { AppIdleService, AppService, AppInstanceService, SessionManagerService, AppIdleComponent } from 'smartauction-frontend-micro-library';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends AppIdleComponent implements OnInit, OnDestroy {
  title = 'SmartAuction';
  environment = environment;

  constructor(
    router: Router,
    idleService: AppIdleService,
    session: SessionManagerService,
    modalService: BsModalService,
    appService: AppService,
    appInstance: AppInstanceService,
  ) {
    super( idleService, session, modalService, appInstance, router );
    appService.initializeOlympus(environment);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}

import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-offer-modals-header',
  templateUrl: './offer-modals-header.component.html',
  styleUrls: ['./offer-modals-header.component.scss']
})
export class OfferModalsHeaderComponent {
  @Input() tenantName: string;
  @Input() heading: string;
  @Input() headingId: string;
}

import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { SmsModalComponent } from './sms-modal/sms-modal.component';
import { RemarketingUiComponentsLibModule } from 'remarketing-ui-components-lib';
import {CommentModule} from '@ally/remarketing/shared/ui';
import {CommonModule} from '@angular/common';
import {RegistrationRoutingModule} from '../../app/modules/registration/registration-routing.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {PaginationModule} from 'ngx-bootstrap/pagination';
import { ModalCloseComponent } from '../ui/comment/modal-actions/modal-close/modal-close.component';
import { VehicleHistoryReportComponent } from './vehicle-history-report/vehicle-history-report.component';
import { TranslocoRootModule, PipeModule} from 'smartauction-frontend-micro-library';
import { BackLinkComponent } from './back-link/back-link.component';
import { SearchPillComponent } from './search-pill/search-pill.component';
import {BannerComponent} from './banner/banner.component';
import { StateIndicatorComponent } from './state-indicator/state-indicator.component';
import { SwVehicleDataHookComponent } from './stockwave/sw-vehicle-data-hook/sw-vehicle-data-hook.component';
import { SwVehicleContainerHookComponent } from './stockwave/sw-vehicle-container-hook/sw-vehicle-container-hook.component';
import { SwIconContainerHookComponent } from './stockwave/sw-icon-container-hook/sw-icon-container-hook.component';
import { DeleteSearchComponent } from './delete-search/delete-search.component';
import { VehicleImagePlaceholderComponent } from './vehicle-image-placeholder/vehicle-image-placeholder.component';
import { CollapsableOfferHistoryComponent } from './offer-modals/collapsable-offer-history/collapsable-offer-history.component';
import { SharedListMultiActionModalComponent } from './offer-modals/shared-list-multi-action-modal/shared-list-multi-action-modal.component';
import { MultiOfferHistoryComponent } from './offer-modals/multi-offer-history/multi-offer-history.component';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { ParseStringPipe } from '../ui/pipes/parse-string/parse-string.pipe';
import {PurchaseOfferHistoryModalComponent} from './offer-modals/purchase-offer-history-modal/purchase-offer-history-modal.component';
import { NgxMaskModule } from 'ngx-mask';
import {PostingHistoryModalComponent} from './offer-modals/posting-history-modal/posting-history-modal.component';
import { WelcomeBannerModalComponent } from './welcome-banner-modal/welcome-banner-modal.component';
import { TransportationSuccessModalComponent } from './transportation-success-modal/transportation-success-modal.component';
import { ViewAllBySellerLinkComponent } from './view-all-by-seller-link/view-all-by-seller-link.component';
import { ArbitrationCloseRequestModalComponent } from '../arbitration/components/arbitration-close-request-modal/arbitration-close-request-modal.component';
import { AuctionSummaryArbitrationFormComponent, AuctionSummaryArbitrationManageComponent, AuctionSummaryArbitrationReadonlyComponent, AuctionSummaryArbitrationVehicleCardComponent, ImagesCountComponent } from '../arbitration';
import { AuctionSummaryArbitrationSubmittedModal } from '../arbitration/components/auction-summary-arbitration-submitted-modal/auction-summary-arbitration-submitted-modal';
import { FormFieldErrorMessageComponent } from '../arbitration/components/form-field-error-message/form-field-error-message.component';
import { NgxsModule } from '@ngxs/store';
import { AuctionSummaryArbitrationState } from 'remarketing-angular-library';
import { VehicleStatusBadgeComponent } from './vehicle-status-badge/vehicle-status-badge.component';
import { VehicleTypeSelectComponent } from './vehicle-type-select/vehicle-type-select.component';
import { AuctionSummaryResultItemBannerComponent } from '../arbitration/components/auction-summary-result-item-banner/auction-summary-result-item-banner.component';

@NgModule({
  declarations: [
  SmsModalComponent,
  VehicleHistoryReportComponent,
  WelcomeBannerModalComponent,
  BackLinkComponent,
  SearchPillComponent,
  BannerComponent,
  StateIndicatorComponent,
  SwVehicleDataHookComponent,
  SwVehicleContainerHookComponent,
  SwIconContainerHookComponent,
  DeleteSearchComponent,
  ParseStringPipe,
  CollapsableOfferHistoryComponent,
  VehicleImagePlaceholderComponent,
  SharedListMultiActionModalComponent,
  MultiOfferHistoryComponent,
  PurchaseOfferHistoryModalComponent,
  PostingHistoryModalComponent,
  TransportationSuccessModalComponent,
  ViewAllBySellerLinkComponent,
  ArbitrationCloseRequestModalComponent,
  AuctionSummaryArbitrationFormComponent,
  AuctionSummaryArbitrationReadonlyComponent,
  AuctionSummaryResultItemBannerComponent,
  AuctionSummaryArbitrationSubmittedModal,
  FormFieldErrorMessageComponent,
  ImagesCountComponent,
  AuctionSummaryArbitrationManageComponent,
  AuctionSummaryArbitrationVehicleCardComponent,
  ArbitrationCloseRequestModalComponent,
  VehicleStatusBadgeComponent,
  VehicleTypeSelectComponent
  ],
  providers: [
  ],
  imports: [
  RemarketingUiComponentsLibModule,
  CommonModule,
  RegistrationRoutingModule,
  FormsModule,
  ReactiveFormsModule,
  PaginationModule.forRoot(),
  RemarketingUiComponentsLibModule,
  PipeModule,
  CommentModule,
  TranslocoRootModule,
  CollapseModule.forRoot(),
  NgxMaskModule.forChild(),
  NgxsModule.forFeature([
    AuctionSummaryArbitrationState,
  ]),
  ],
  exports: [
  SmsModalComponent,
  VehicleImagePlaceholderComponent,
  ModalCloseComponent,
  VehicleHistoryReportComponent,
  BackLinkComponent,
  SearchPillComponent,
  BannerComponent,
  ParseStringPipe,
  StateIndicatorComponent,
  SwVehicleDataHookComponent,
  SwVehicleContainerHookComponent,
  SwIconContainerHookComponent,
  DeleteSearchComponent,
  WelcomeBannerModalComponent,
  CollapsableOfferHistoryComponent,
  VehicleImagePlaceholderComponent,
  SharedListMultiActionModalComponent,
  MultiOfferHistoryComponent,
  PurchaseOfferHistoryModalComponent,
  PostingHistoryModalComponent,
  TransportationSuccessModalComponent,
  ViewAllBySellerLinkComponent,
  ArbitrationCloseRequestModalComponent,
  AuctionSummaryArbitrationFormComponent,
  AuctionSummaryArbitrationReadonlyComponent,
  AuctionSummaryResultItemBannerComponent,
  AuctionSummaryArbitrationSubmittedModal,
  FormFieldErrorMessageComponent,
  ImagesCountComponent,
  AuctionSummaryArbitrationManageComponent,
  AuctionSummaryArbitrationVehicleCardComponent,
  ArbitrationCloseRequestModalComponent,
  VehicleStatusBadgeComponent,
  VehicleTypeSelectComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
  })
export class SharedModule {
}

import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-action-submitting',
  templateUrl: './action-submitting.component.html',
  styleUrls: ['./action-submitting.component.scss']
})
export class ActionSubmittingComponent implements OnInit, AfterViewInit {
  constructor(private readonly changeDetector: ChangeDetectorRef) {}

  @ViewChild('tagline') tagline: ElementRef<HTMLElement>;
  @Input() actionText: string;

  ngOnInit() {
    setTimeout(() => { //timeout is necessary since we are working outside of the Angular ngZone
      this.changeDetector.markForCheck();
    }, 2000);
  }

  ngAfterViewInit(): void {
    this.tagline.nativeElement.focus();
  }

  blockEsc(e) {
    if (e.keyCode === 27) {
      e.stopImmediatePropagation();
    }
  }
}

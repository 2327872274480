<ng-container *transloco="let convert;">
<app-modal modalId="Save-Comment">
  <app-action-submitting *ngIf="state === 'loading'" actionText="{{convert('shared.saveComment.actionTextSubmittingComment')}}"></app-action-submitting>
  <app-action-submitted
    *ngIf="state === 'success'"
    (close)="close()"
    actionText="{{convert('shared.saveComment.actionTextCommentSaved')}}"
    [tenantName]="tenantName"
  ></app-action-submitted>
  <app-action-error
    *ngIf="state === 'error'"
    (close)="close()"
    [tenantName]="tenantName"
    [errorText]="errorText"
  ></app-action-error>
</app-modal>
</ng-container>

<ng-container *transloco="let convert;">
<app-modal [modalId]="modalId">
  <app-modal-close (close)="close()" [identifier]="'sms_close_x_button'"></app-modal-close>
  <div class="container">

  <ng-container *ngIf="showConfirmationView()">
      <header class="modal__header">
        <h1 class="modal__title fw-bold" [attr.id]="modalId + '-title'">
          {{convert('shared.smsModal.textOneTime')}}
        </h1>
      </header>
      <div class="modal__content">
        <p class="modal__content--info">
          {{convert('shared.smsModal.textMustVerify')}}
        </p>
        <p class="modal__content--info">
          <strong>{{convert('shared.smsModal.textConfirmSecurity')}}</strong>
        </p>
        <p class="modal__content--info">
          {{maskedPhoneNumber}} {{convert('shared.smsModal.textMessage')}}
        </p>
      </div>
      <div class="modal__actions">
        <button
          id="sms_verify_button"
          class="btn btn-primary me-md-2 mb-md-0"
          (click)="confirmation()"
          data-track-elem="button"
          data-track-name="Verify Code"
        >
          {{convert('shared.smsModal.btnSend')}}
        </button>
        <button
          id="bnm_cancel_button"
          class="btn btn-link p-0 me-md-2 mb-md-0"
          (click)="close()"
          data-track-elem="button"
          data-track-name="Cancel"
          data-track-trigger="close"
        >
          {{convert('common.btnCancel')}}
        </button>
      </div>
    </ng-container>

    <ng-container *ngIf="showActivateView()">
      <header class="modal__header">
        <h1 class="modal__title okta-form-title o-form-head fw-bold " [attr.id]="modalId + '-title'">
          {{convert('shared.smsModal.textEnterSecurity')}}
        </h1>
      </header>
      <div class="modal__content">
        <p class="modal__content--info" [innerHTML]="convert('shared.smsModal.textPleaseEnter', {maskedPhoneNumber : maskedPhoneNumber})"></p>

        <label class="fw-bold" style="font-size: smaller" for="passCode">{{convert('shared.smsModal.textCode')}}</label>
        <div class="modal__actions resend-code">
          <input class="otp"
            [ngClass]="getErrorStatus()"
            type="text"
            id="passCode"
            required
            aria-required="true"
            [(ngModel)]="passCode"
          />
          <button
            class="btn btn-link p-0 me-md-2 mb-md-0"
            (click)="enrollSms()"
            data-track-elem="button"
            data-track-name="Resend"
            data-track-trigger="close"
            [disabled]="isResendBtnDisable"
          >
            {{convert('shared.smsModal.btnResendCode')}}
          </button>
        </div>

        <ng-container *ngIf="showError()">
          <p class="text-danger fw-bold"> {{errorMessage}} </p>
        </ng-container>
      </div>
      <div class="modal__actions">
        <button
          id="sms_verify_button"
          class="btn btn-primary me-md-2 mb-md-0"
          (click)="activateSms()"
          data-track-elem="button"
          data-track-name="Verify Code"
        >
          {{convert('shared.smsModal.btnVerifyCode')}}
        </button>
        <button
          id="bnm_cancel_button"
          class="btn btn-link p-0 me-md-2 mb-md-0"
          (click)="close()"
          data-track-elem="button"
          data-track-name="Cancel"
          data-track-trigger="close"
        >
          {{convert('common.btnCancel')}}
        </button>
      </div>
    </ng-container>

    <ng-container *ngIf="showSuccessView()">
      <div class="d-flex justify-content-center">
        <h1 class="fw-bold modal__title">
        <span aria-hidden="true" class="indicator-success me-3"><em class="allycon-checkmark indicator"></em></span>
          {{convert('shared.smsModal.textMobilePhoneVerified')}}
        </h1>
      </div>

      <div class="d-flex justify-content-center">
        <button
          id="sms_close_button"
          class="btn btn-primary"
          (click)="close()"
          data-track-elem="button"
          data-track-name="Close"
        >
          {{convert('common.btnClose')}}
        </button>
      </div>
    </ng-container>
  </div>
</app-modal>
</ng-container>
